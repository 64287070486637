import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'

function MixPage() {
  const [mix, setMix] = useState(null)
  const { id } = useParams()

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
  } = useMediaActions('mix', id)

  useEffect(() => {
    const fetchMix = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}mixes/${id}/`)
        setMix(response.data)
      } catch (error) {
        console.error('Error fetching mix:', error)
      }
    }

    fetchMix()
  }, [id])

  if (!mix) return <div>Loading...</div>

  return (
    <div>
      <h1>{mix.title}</h1>
      <p>Added at: {new Date(mix.added_at).toLocaleString()}</p>
      <MediaActionButton
        onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
        isActive={inQueue}
        isLoading={queueLoading}
        actionType="Queue"
      />
      <br />
      <MediaActionButton
        onClick={() => handleCollectionAction(inCollection)}
        isActive={inCollection}
        isLoading={collectionLoading}
        actionType="Collection"
      />
      {mix.src_url && (
        <p>
          <a href={mix.src_url} target="_blank" rel="noopener noreferrer">
            View Original Mix
          </a>
        </p>
      )}
    </div>
  )
}

export default MixPage
