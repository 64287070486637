import { useState } from 'react'
import { Link } from 'react-router-dom'
import MediaActionButton from '../../common/MediaActionButton'
import { getServiceLinks, renderServiceLinksWithSeparators } from '../utils/serviceLinks'

function QueueSection({
  userMedia,
  loadingQueueItems,
  setLoadingQueueItems,
  handleMoveToCollection,
  handleRemoveFromQueue,
}) {
  const [removeLoadingStates, setRemoveLoadingStates] = useState({})

  const handleRemoveWithLoading = async (mediaType, id) => {
    try {
      setRemoveLoadingStates((prev) => ({ ...prev, [id]: true }))
      await handleRemoveFromQueue(mediaType, id)
    } finally {
      setRemoveLoadingStates((prev) => ({ ...prev, [id]: false }))
    }
  }

  const renderQueueItem = (item) => (
    <li key={item.queue_id}>
      <div className='metadata'>
        <Link to={`/${item.media_type}/${item.id}`} className='metadata-text primary interactive'>
          {item.title}
        </Link>

        {item.primary_artists && (
          <>
            <span key={`by-${item.queue_id}`} className='metadata-text secondary'>
              {' '}
              by{' '}
            </span>
            {item.primary_artists
              .map((artist) => (
                <Link
                  key={artist.id}
                  to={`/artist/${artist.id}`}
                  className='metadata-text primary interactive'
                >
                  {artist.name}
                </Link>
              ))
              .reduce((prev, curr, index) => [
                prev,
                <span key={`separator-${index}`}>, </span>,
                curr,
              ])}
          </>
        )}

        {item.media_type === 'playlist' && item.song_count && (
          <span key={`song-count-${item.queue_id}`} className='metadata-text secondary'>
            {' '}
            ({item.song_count} songs)
          </span>
        )}

        <div className='service-links'>
          <span className='metadata-text secondary media-type'>{item.media_type}</span>
          <span className='separator slash'>/</span>
          {renderServiceLinksWithSeparators(getServiceLinks(item, item.media_type))}
        </div>
      </div>

      <div className='media-actions'>
        <MediaActionButton
          onClick={() => handleMoveToCollection(item.queue_id)}
          isLoading={loadingQueueItems[item.queue_id]}
          actionType='Collection'
          isQueueSection={true}
        />
        <MediaActionButton
          onClick={() => handleRemoveWithLoading(item.media_type, item.id)}
          isLoading={removeLoadingStates[item.id]}
          actionType='Remove'
        />
      </div>
    </li>
  )

  return (
    <section id='queue'>
      <div className='centered-list'>
        <ul className='left-justify'>
          {userMedia.queue
            .sort((a, b) => new Date(a.added_at) - new Date(b.added_at))
            .map(renderQueueItem)}
        </ul>
      </div>
    </section>
  )
}

export default QueueSection
