import { useState, useEffect } from 'react'
import axios from 'axios'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { setAuthToken } from '../services/auth'

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  tabs: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
    borderBottom: '1px solid #eee',
    paddingBottom: '10px',
  },
  tab: {
    padding: '8px 16px',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    fontSize: '16px',
    color: '#666',
  },
  activeTab: {
    color: '#000',
    borderBottom: '2px solid #000',
  },
  mediaList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  mediaItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    transition: 'background-color 0.2s',
  },
  mediaIcon: {
    fontSize: '24px',
    marginRight: '16px',
  },
  mediaInfo: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    color: '#333',
    textDecoration: 'none',
  },
  details: {
    margin: '4px 0 0',
    color: '#666',
    fontSize: '0.9em',
  },
  deleteButton: {
    padding: '8px 16px',
    backgroundColor: '#ff4444',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  error: {
    color: '#ff4444',
    padding: '16px',
    textAlign: 'center',
  },
}

const Recent = () => {
  const [recentMedia, setRecentMedia] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [activeTab, setActiveTab] = useState('all')

  const tabs = [
    { id: 'all', label: 'All' },
    { id: 'song', label: 'Songs' },
    { id: 'album', label: 'Albums' },
    { id: 'playlist', label: 'Playlists' },
    { id: 'mix', label: 'Mixes' },
    { id: 'collage', label: 'Collages' },
  ]

  useEffect(() => {
    fetchRecentMedia()
  }, [])

  const fetchRecentMedia = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      const response = await axios.get('/api/recent-media/')
      setRecentMedia(response.data)
      setLoading(false)
    } catch (err) {
      setError('Failed to load recent media')
      setLoading(false)
    }
  }

  const handleDelete = async (mediaType, libId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        setAuthToken(localStorage.getItem('token'))
        await axios.delete(`/api/delete/${mediaType}/${libId}/`)
        setRecentMedia((prevMedia) =>
          prevMedia.filter((item) => !(item.type === mediaType && item.lib_id === libId))
        )
      } catch (err) {
        setError('Failed to delete item')
      }
    }
  }

  const getMediaIcon = (mediaType) => {
    switch (mediaType) {
      case 'song':
        return '🎵'
      case 'album':
        return '💿'
      case 'playlist':
        return '📝'
      case 'mix':
        return '🎚️'
      case 'collage':
        return '🖼️'
      default:
        return '📌'
    }
  }

  const filteredMedia = recentMedia.filter((item) => activeTab === 'all' || item.type === activeTab)

  if (loading) return <div style={styles.container}>Loading...</div>
  if (error) return <div style={styles.error}>{error}</div>

  return (
    <div style={styles.container}>
      <h1>Recently Added</h1>

      <div style={styles.tabs}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            style={{
              ...styles.tab,
              ...(activeTab === tab.id ? styles.activeTab : {}),
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div style={styles.mediaList}>
        {filteredMedia.map((item) => (
          <div key={`${item.type}-${item.lib_id}`} style={styles.mediaItem}>
            <span style={styles.mediaIcon}>{getMediaIcon(item.type)}</span>
            <div style={styles.mediaInfo}>
              <Link to={`/${item.type}s/${item.lib_id}`} style={{ textDecoration: 'none' }}>
                <h3 style={styles.title}>{item.title}</h3>
              </Link>
              <p style={styles.details}>
                {item.type.charAt(0).toUpperCase() + item.type.slice(1)} • Added{' '}
                {format(new Date(item.added_at), 'MMM d, yyyy')}
              </p>
            </div>
            <button
              style={styles.deleteButton}
              onClick={() => handleDelete(item.type, item.lib_id)}
              aria-label={`Delete ${item.title}`}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#ff0000')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#ff4444')}
            >
              Delete
            </button>
          </div>
        ))}
        {filteredMedia.length === 0 && (
          <p style={{ textAlign: 'center', color: '#666' }}>
            No {activeTab === 'all' ? 'media' : activeTab + 's'} found
          </p>
        )}
      </div>
    </div>
  )
}

export default Recent
