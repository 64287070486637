import { useState, useRef, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ReactDOM from 'react-dom'

function AccountDropdown({ handleLogout }) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const menuRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const linkClasses =
    'text-contextBlack transition hover:bg-contextBlack hover:text-contextWhite px-2 py-1 block w-full text-left text-[14px] account-dropdown-link'

  const handleMenuClick = (e, path, callback) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(false)
    if (callback) {
      callback()
    } else if (path) {
      navigate(path)
    }
  }

  const renderDropdown = () => {
    if (!isOpen) return null

    return ReactDOM.createPortal(
      <div
        ref={menuRef}
        className='fixed right-[20px] top-[60px] w-24 border border-contextGray bg-contextWhite shadow-sm'
        style={{ zIndex: 99999 }}
      >
        <Link to='/profile' className={linkClasses} onClick={(e) => handleMenuClick(e, '/profile')}>
          profile
        </Link>
        <Link
          to='/logout'
          className={linkClasses}
          onClick={(e) => handleMenuClick(e, null, handleLogout)}
        >
          logout
        </Link>
      </div>,
      document.body
    )
  }

  return (
    <div className='relative' ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='account-button group rounded-[30px] p-2 transition'
      >
        <img src='/account.svg' alt='Account' className='h-6 w-6 transition' />
      </button>
      {renderDropdown()}
    </div>
  )
}

export default AccountDropdown
