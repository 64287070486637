function MediaActionButton({
  onClick,
  isActive,
  isLoading,
  actionType, // 'Queue' or 'Collection'
  loadingText, // Optional custom loading text
  className = '',
}) {
  const baseClassName = `media-action-button ${isActive ? 'inverted' : ''} ${className}`

  const getButtonText = () => {
    if (isLoading) {
      if (loadingText) return loadingText
      return isActive ? 'Removing...' : 'Adding...'
    }
    return isActive ? `- ${actionType}` : `+ ${actionType}`
  }

  return (
    <button onClick={onClick} className={baseClassName} disabled={isLoading}>
      {getButtonText()}
    </button>
  )
}

export default MediaActionButton
