const HeroPage = () => {
  return (
    <div className="h-dvh w-dvw bg-contextBlack">
      <div className="container flex justify-center">
        <img className="h-auto w-auto" src="/lettermark.png" alt="Context" />
      </div>
    </div>
  )
}

export default HeroPage
