import API_URL from '../config'

let musicKit
let developerToken

const fetchDeveloperToken = async () => {
  try {
    const response = await fetch(`${API_URL}apple-music-token/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const data = await response.json()
    return data.token
  } catch (error) {
    console.error('Error fetching Apple Music token:', error)
    return null
  }
}

export const initializeAppleMusic = async () => {
  if (musicKit) {
    return musicKit
  }

  if (!developerToken) {
    developerToken = await fetchDeveloperToken()
  }

  return new Promise((resolve) => {
    const configureMusicKit = () => {
      window.MusicKit.configure({
        developerToken: developerToken,
        app: {
          name: 'Your App Name',
          build: '1.0.0',
        },
      })
      musicKit = window.MusicKit.getInstance()
      resolve(musicKit)
    }

    if (window.MusicKit) {
      configureMusicKit()
    } else {
      document.addEventListener('musickitloaded', configureMusicKit)
    }
  })
}

export const searchAppleMusic = async (query) => {
  if (!musicKit) {
    musicKit = await initializeAppleMusic()
  }
  try {
    const results = await musicKit.api.search(query, {
      types: 'songs,albums',
      limit: 5,
    })
    return {
      tracks: results.songs
        ? results.songs.data.map((song) => ({
            id: song.id,
            name: song.attributes.name,
            artists: [{ name: song.attributes.artistName }],
            album: {
              id: null,
              name: song.attributes.albumName,
              images: [{ url: formatArtworkURL(song.attributes.artwork.url) }],
            },
            preview_url: song.attributes.previews[0].url,
            track_number: song.attributes.trackNumber,
          }))
        : [],
      albums: results.albums
        ? results.albums.data.map((album) => ({
            id: album.id,
            name: album.attributes.name,
            artists: [{ name: album.attributes.artistName }],
            images: [{ url: formatArtworkURL(album.attributes.artwork.url) }],
            total_tracks: album.attributes.trackCount,
          }))
        : [],
    }
  } catch (error) {
    console.error('Apple Music search error:', error)
    if (error.name === 'QUOTA_EXCEEDED') {
      throw new Error('QUOTA_EXCEEDED')
    }
    throw error
  }
}

export const handleAppleMusicLogin = async () => {
  if (!musicKit) {
    await initializeAppleMusic()
  }
  return musicKit.authorize()
}

function formatArtworkURL(url) {
  return url.replace('{w}', '20').replace('{h}', '20')
}

export const getAppleMusicLoginStatus = () => {
  return musicKit ? musicKit.isAuthorized : false
}

export const getAppleMusicAlbumForSong = async (songId) => {
  if (!/^\d+$/.test(songId)) {
    console.error('Invalid Apple Music ID format:', songId)
    return { error: 'Invalid ID format' }
  }
  if (!musicKit) {
    musicKit = await initializeAppleMusic()
  }
  try {
    const result = await musicKit.api.song(songId, { include: 'albums' })
    if (
      result &&
      result.relationships &&
      result.relationships.albums &&
      result.relationships.albums.data.length > 0
    ) {
      const album = result.relationships.albums.data[0]
      console.log('ALBUM', album)
      return {
        albumId: album.id,
        albumName: album.attributes.name,
        albumArtist: album.attributes.artistName,
        trackNumber: result.attributes.trackNumber,
      }
    }
    return {
      albumId: null,
      albumName: null,
      albumArtist: null,
      trackNumber: null,
    }
  } catch (error) {
    console.error('Error fetching album info for song:', error)
    return {
      albumId: null,
      albumName: null,
      albumArtist: null,
      trackNumber: null,
    }
  }
}

export const getAppleMusicAlbumTracks = async (albumId) => {
  if (!musicKit) {
    musicKit = await initializeAppleMusic()
  }
  try {
    const album = await musicKit.api.album(albumId)
    return album.relationships.tracks.data.map((track) => ({
      name: track.attributes.name,
      track_number: track.attributes.trackNumber,
      id: track.id,
      artists: [{ name: track.attributes.artistName }],
      duration_ms: track.attributes.durationInMillis,
    }))
  } catch (error) {
    console.error('Error fetching Apple Music album tracks:', error)
    throw error
  }
}

export const getAppleMusicItemArtists = async (itemId, itemType = 'song') => {
  if (!musicKit) {
    musicKit = await initializeAppleMusic()
  }
  try {
    let result
    if (itemType === 'song') {
      result = await musicKit.api.song(itemId, { include: 'artists' })
    } else if (itemType === 'album') {
      result = await musicKit.api.album(itemId, { include: 'artists' })
    } else {
      throw new Error('Invalid item type')
    }

    if (
      result.relationships &&
      result.relationships.artists &&
      result.relationships.artists.data
    ) {
      return result.relationships.artists.data.map((artist) => ({
        id: artist.id,
        name: artist.attributes.name,
      }))
    }
    return []
  } catch (error) {
    console.error(`Error fetching Apple Music ${itemType} artists:`, error)
    return []
  }
}

export const composeAppleMusicArtistNames = (title, artists) => {
  const featRegex = /\(feat\.\s*(.*?)\)/i
  const match = title.match(featRegex)

  let allArtists = [...artists]
  let cleanTitle = title

  if (match) {
    const featuredArtists = match[1]
      .split(/,\s*&\s*|\s*&\s*|,\s*/)
      .map((name) => name.trim())
    allArtists = [...new Set([...allArtists, ...featuredArtists])]
    cleanTitle = title.replace(featRegex, '').trim()
  }

  return { cleanTitle, allArtists }
}
