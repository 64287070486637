import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'
import CollageSelectionModal from './CollageSelectionModal'
import { useUserMedia } from './Library/hooks/useUserMedia'

function AlbumPage() {
  const [album, setAlbum] = useState(null)
  const { id } = useParams()
  const [trackLoadingStatus, setTrackLoadingStatus] = useState({})
  const [isPartialCollection, setIsPartialCollection] = useState(false)
  const [showCollageModal, setShowCollageModal] = useState(false)
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
  const [selectedMediaForCollage, setSelectedMediaForCollage] = useState({
    mediaType: 'album',
    mediaId: id,
  })

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    fetchUserMedia,
    itemCollectionStatus,
    userCollection,
    isPartial,
  } = useMediaActions('album', id, album?.tracks || [])

  const { userMedia } = useUserMedia()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const albumResponse = await axios.get(`${API_URL}albums/${id}/`)
        setAlbum(albumResponse.data)

        const loadingStatus = {}
        albumResponse.data.tracks.forEach((track) => {
          loadingStatus[track.id] = false
        })
        setTrackLoadingStatus(loadingStatus)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [id])

  useEffect(() => {
    const checkPartialStatus = () => {
      if (!album || !itemCollectionStatus) return

      // Get the album's collection status including direct_add flag
      const albumInCollection = userCollection?.find(
        (a) => a.media_type === 'album' && a.id === parseInt(id)
      )

      // Album is partial if not all tracks are in collection or direct_add is false
      const hasAllTracks = album.tracks.every((track) => itemCollectionStatus[track.id])
      setIsPartialCollection(!hasAllTracks || !albumInCollection?.direct_add)
    }

    checkPartialStatus()
  }, [album, itemCollectionStatus, userCollection, id])

  const handleitemCollectionAction = async (trackId, isRemoving) => {
    try {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: true }))
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/song/${trackId}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: 'song',
          id: trackId,
        })
      }
      await fetchUserMedia()
    } catch (error) {
      console.error(`Error ${isRemoving ? 'removing' : 'adding'} track to collection:`, error)
    } finally {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: false }))
    }
  }

  const renderListenLinks = (track) => {
    const links = []
    if (track.spotify_id)
      links.push(
        <a
          key='spotify'
          href={`https://open.spotify.com/track/${track.spotify_id}`}
          target='_blank'
          rel='noopener noreferrer'
          className='service-link'
        >
          Spotify
        </a>
      )
    if (track.apple_id)
      links.push(
        <a
          key='apple'
          href={`https://music.apple.com/us/song/${track.apple_id}`}
          target='_blank'
          rel='noopener noreferrer'
          className='service-link'
        >
          Apple Music
        </a>
      )
    if (track.bandcamp_url)
      links.push(
        <a
          key='bandcamp'
          href={track.bandcamp_url}
          target='_blank'
          rel='noopener noreferrer'
          className='service-link'
        >
          Bandcamp
        </a>
      )
    if (track.youtube_id)
      links.push(
        <a
          key='youtube'
          href={`https://www.youtube.com/watch?v=${track.youtube_id}`}
          target='_blank'
          rel='noopener noreferrer'
          className='service-link'
        >
          YouTube
        </a>
      )
    return links.length > 0 ? (
      <div className='service-links'>
        {links.reduce(
          (prev, curr, idx) =>
            idx === 0
              ? [curr]
              : [
                  ...prev,
                  <span key={`separator-${idx}`} className='separator'>
                    {' '}
                    •{' '}
                  </span>,
                  curr,
                ],
          []
        )}
      </div>
    ) : (
      <span className='metadata-text secondary'>No links available</span>
    )
  }

  const handleAddToCollage = (event, mediaType = 'album', mediaId = id) => {
    const button = event.currentTarget
    const rect = button.getBoundingClientRect()

    // Toggle the modal
    if (showCollageModal) {
      setShowCollageModal(false)
    } else {
      setModalPosition({
        top: rect.bottom,
        left: rect.left,
      })
      setShowCollageModal(true)
    }
    // Store the selected media info using consistent parameter names
    setSelectedMediaForCollage({ type: mediaType, id: parseInt(mediaId) })
  }

  if (!album) return <div>Loading...</div>

  return (
    <div className='media-list'>
      <div className='media-header'>
        <h1 className='metadata-text primary'>{album.title}</h1>
        <div className='media-actions'>
          <MediaActionButton
            onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
            isActive={inQueue}
            isLoading={queueLoading}
            actionType='Queue'
          />
          <MediaActionButton
            onClick={() => handleCollectionAction(inCollection)}
            isActive={inCollection}
            isLoading={collectionLoading}
            actionType='Collection'
            isPartial={isPartial}
          />
          <MediaActionButton
            onClick={handleAddToCollage}
            isActive={false}
            isLoading={false}
            actionType='Collage'
          />
        </div>
      </div>

      <div className='metadata'>
        <div className='info-line'>
          {album.primary_artists && (
            <>
              <span className='metadata-text secondary'>Artists: </span>
              {album.primary_artists
                .map((artist) => (
                  <Link
                    key={artist.id}
                    to={`/artist/${artist.id}`}
                    className='metadata-text primary interactive'
                  >
                    {artist.name}
                  </Link>
                ))
                .reduce((prev, curr) => [prev, ', ', curr])}
            </>
          )}
        </div>
        {album.label && (
          <div className='info-line'>
            <span className='metadata-text secondary'>Label: </span>
            <span className='metadata-text primary'>{album.label}</span>
          </div>
        )}
        <div className='info-line'>
          <span className='metadata-text secondary'>Tracks: {album.tracks.length}</span>
        </div>
        <div className='service-links center'>
          {album.spotify_id && (
            <a
              href={`https://open.spotify.com/album/${album.spotify_id}`}
              target='_blank'
              rel='noopener noreferrer'
              className='service-link'
            >
              Spotify
            </a>
          )}
          {album.apple_id && (
            <>
              {album.spotify_id && <span className='separator'> • </span>}
              <a
                href={`https://music.apple.com/us/album/${album.apple_id}`}
                target='_blank'
                rel='noopener noreferrer'
                className='service-link'
              >
                Apple Music
              </a>
            </>
          )}
          {album.bandcamp_url && (
            <>
              {(album.spotify_id || album.apple_id) && <span className='separator'> • </span>}
              <a
                href={album.bandcamp_url}
                target='_blank'
                rel='noopener noreferrer'
                className='service-link'
              >
                Bandcamp
              </a>
            </>
          )}
        </div>
      </div>

      <div className='mt-5'>
        <ul className='left-justify'>
          {album.tracks.map((track) => (
            <li key={track.id}>
              <div className='metadata'>
                <Link to={`/song/${track.id}`} className='metadata-text primary interactive'>
                  {track.title}
                </Link>
                {track.primary_artists && (
                  <>
                    <span className='metadata-text secondary'> by </span>
                    {track.primary_artists
                      .map((artist) => (
                        <Link
                          key={artist.id}
                          to={`/artist/${artist.id}`}
                          className='metadata-text primary interactive'
                        >
                          {artist.name}
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ', ', curr])}
                  </>
                )}
                <div className='service-links'>{renderListenLinks(track)}</div>
              </div>
              <div className='media-actions'>
                <MediaActionButton
                  onClick={() =>
                    handleitemCollectionAction(track.id, itemCollectionStatus[track.id])
                  }
                  isActive={itemCollectionStatus[track.id]}
                  isLoading={trackLoadingStatus[track.id]}
                  actionType='Collection'
                />
                <MediaActionButton
                  onClick={(event) => handleAddToCollage(event, 'song', track.id)}
                  isActive={false}
                  isLoading={false}
                  actionType='Collage'
                />
              </div>
            </li>
          ))}
        </ul>
      </div>

      {showCollageModal && (
        <CollageSelectionModal
          position={modalPosition}
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={selectedMediaForCollage}
          fetchUserMedia={fetchUserMedia}
          collages={userMedia?.collection?.collages || []}
        />
      )}
    </div>
  )
}

export default AlbumPage
