import { getSpotifyLink, getYouTubeLink, getBandcampLink, getAppleMusicLink } from './mediaLinks'

export const getServiceLinks = (item, type) => {
  const links = []

  if (item.spotify_id) {
    links.push(
      <a
        key='spotify'
        href={getSpotifyLink(type === 'song' ? 'track' : type, item.spotify_id)}
        target='_blank'
        rel='noopener noreferrer'
        className='service-link'
      >
        Spotify
      </a>
    )
  }

  if (item.apple_id) {
    links.push(
      <a
        key='apple'
        href={getAppleMusicLink(type, item.apple_id)}
        target='_blank'
        rel='noopener noreferrer'
        className='service-link'
      >
        Apple Music
      </a>
    )
  }

  if (item.youtube_id) {
    links.push(
      <a
        key='youtube'
        href={getYouTubeLink(item.youtube_id, type)}
        target='_blank'
        rel='noopener noreferrer'
        className='service-link'
      >
        YouTube
      </a>
    )
  }

  if (item.bandcamp_url) {
    links.push(
      <a
        key='bandcamp'
        href={getBandcampLink(item.bandcamp_url)}
        target='_blank'
        rel='noopener noreferrer'
        className='service-link'
      >
        Bandcamp
      </a>
    )
  }

  if (item.src_url && (type === 'mix' || (type === 'playlist' && item.is_external))) {
    let serviceName = 'Source'
    if (item.src_url.includes('spotify.com')) serviceName = 'Spotify'
    else if (item.src_url.includes('music.apple.com')) serviceName = 'Apple Music'
    else if (item.src_url.includes('youtube.com')) serviceName = 'YouTube'
    else if (item.src_url.includes('bandcamp.com')) serviceName = 'Bandcamp'

    links.push(
      <a
        key='source'
        href={item.src_url}
        target='_blank'
        rel='noopener noreferrer'
        className='service-link'
      >
        {serviceName}
      </a>
    )
  }

  return links.length > 0 ? links : null
}

export const renderServiceLinksWithSeparators = (links) => {
  if (!links || links.length === 0) {
    return <span className='metadata-text secondary'>No links available</span>
  }

  return links.reduce(
    (prev, curr, idx) =>
      idx === 0
        ? [curr]
        : [
            ...prev,
            <span key={`separator-${idx}`} className='separator'>
              {' '}
              •{' '}
            </span>,
            curr,
          ],
    []
  )
}
