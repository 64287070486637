import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../services/auth'

function Login({ setAuth }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      console.log('Submitting login form')
      const result = await login(username, password)
      console.log('Login result:', result)
      setAuth(true)
      navigate('/library')
    } catch (error) {
      console.error('Login failed:', error)
      setError(
        error.response?.data?.detail || 'Login failed. Please try again.'
      )
    }
  }

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form className="mt-5" onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button className="context-button" type="submit">
          Login
        </button>
      </form>
    </div>
  )
}

export default Login
