import { useState, useEffect, useCallback } from 'react'
import {
  initialize,
  getLoginStatus,
  getCurrentService,
  setCurrentService,
} from '../services/musicServices'
import SearchResults from './SearchResults'
import UrlSubmitForm from './UrlSubmitForm'
import ServiceSelector from './ServiceSelector'
import useSearch from '../hooks/useSearch'
import useAudioPlayer from '../hooks/useAudioPlayer'

function UniversalAdd() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [currentService, setCurrentServiceState] = useState(getCurrentService())
  const [musicKit, setMusicKit] = useState(null)
  const [error, setError] = useState(null)
  const [successMessage, setSuccessMessage] = useState('')

  const { query, setQuery, results, debouncedSearch } = useSearch(
    loggedIn,
    currentService
  )
  const { playing, playPreview } = useAudioPlayer(currentService, musicKit)

  const initializeService = useCallback(async () => {
    const initializedService = await initialize()
    const isLoggedIn = getLoginStatus()
    setLoggedIn(isLoggedIn)

    if (currentService === 'apple_music' && !musicKit) {
      setMusicKit(initializedService)
    }
  }, [currentService, musicKit])

  useEffect(() => {
    initializeService()
  }, [initializeService])

  const handleServiceChange = async (service) => {
    setCurrentService(service)
    setCurrentServiceState(service)
    setLoggedIn(false)
    await initializeService()
  }

  if (!loggedIn) {
    return (
      <div>
        <UrlSubmitForm
          setError={setError}
          setSuccessMessage={setSuccessMessage}
        />
        <ServiceSelector
          currentService={currentService}
          handleServiceChange={handleServiceChange}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      </div>
    )
  }

  return (
    <div>
      <UrlSubmitForm
        setError={setError}
        setSuccessMessage={setSuccessMessage}
      />
      <h4>Currently using: {currentService}</h4>
      <ServiceSelector
        currentService={currentService}
        handleServiceChange={handleServiceChange}
      />
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search for tracks, albums, or playlists..."
        className="search-input"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <SearchResults
        results={results}
        currentService={currentService}
        playing={playing}
        playPreview={playPreview}
      />
    </div>
  )
}

export default UniversalAdd
