import { useState, useEffect } from 'react'
import axios from 'axios'
import API_URL from '../config'
import { setAuthToken } from '../services/auth'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import { useNotification } from '../contexts/NotificationContext'

function Recs() {
  const [recs, setRecs] = useState([])
  const [newRec, setNewRec] = useState({
    username: '',
    media_type: '',
    media_id: '',
    message: '',
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [friendSearchQuery, setFriendSearchQuery] = useState('')
  const [friendSearchResults, setFriendSearchResults] = useState([])
  const { setHasNewRecs } = useNotification()

  useEffect(() => {
    fetchRecs()
    // Clear the new recs notification after a 3-second delay
    const timer = setTimeout(() => {
      setHasNewRecs(false)
      localStorage.removeItem('hasNewRecs')
    }, 3000)

    // Clean up the timer
    return () => clearTimeout(timer)
  }, [])

  const fetchRecs = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      const response = await axios.get(`${API_URL}get-recs/`)
      const newRecs = response.data
      setRecs(newRecs)

      localStorage.setItem('recs', JSON.stringify(newRecs))
    } catch (error) {
      console.error('Error fetching recs:', error)
    }
  }

  useEffect(() => {
    // Load stored recs on component mount
    const storedRecs = JSON.parse(localStorage.getItem('recs') || '[]')
    setRecs(storedRecs)

    // Fetch new recs
    fetchRecs()
  }, [])

  const sendRec = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      await axios.post(`${API_URL}send-rec/`, newRec)
      alert('Rec sent successfully!')
      setNewRec({ username: '', media_type: '', media_id: '', message: '' })
      fetchRecs()
    } catch (error) {
      console.error('Error sending rec:', error)
      alert('Failed to send rec')
    }
  }

  const getSpotifyLink = (type, id) => {
    return `https://open.spotify.com/${type}/${id}`
  }

  const getYouTubeLink = (id, type) => {
    if (type === 'album') {
      return `https://www.youtube.com/playlist?list=${id}`
    }
    return `https://www.youtube.com/watch?v=${id}`
  }

  const getBandcampLink = (url) => {
    return url
  }

  const getAppleMusicLink = (type, id) => {
    if (type === 'song') {
      return `https://music.apple.com/us/song/${id}`
    } else if (type === 'album') {
      return `https://music.apple.com/us/album/${id}`
    }
    return '#'
  }

  const handleSearch = async (query) => {
    if (query.length < 2) {
      setSearchResults([])
      return
    }
    try {
      const response = await axios.get(`${API_URL}search-media/?query=${query}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error searching media:', error)
    }
  }

  const debouncedSearch = debounce(async (query) => {
    if (query.length < 2) {
      setSearchResults([])
      return
    }
    try {
      const response = await axios.get(`${API_URL}search-media/?query=${query}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error searching media:', error)
    }
  }, 300) // 300ms delay

  const debouncedFriendSearch = debounce(async (query) => {
    if (query.length < 2) {
      setFriendSearchResults([])
      return
    }
    try {
      const response = await axios.get(
        `${API_URL}search-friends/?query=${query}`
      )
      setFriendSearchResults(response.data)
    } catch (error) {
      console.error('Error searching friends:', error)
    }
  }, 300) // 300ms delay

  const clearRecs = () => {
    localStorage.removeItem('recs')
    setRecs([])
    setHasNewRecs(false)
  }

  return (
    <div className="library-container mt-5">
      <div className="centered-list">
        <ul className="left-justify">
          {recs.map((rec) => (
            <li key={rec.id}>
              <Link to={`/${rec.media_type}/${rec.media_id}`}>
                <strong>{rec.media_title}</strong>
              </Link>
              {rec.media_type !== 'playlist' &&
                rec.media_type !== 'mix' &&
                rec.primary_artists && (
                  <div>
                    by{' '}
                    {rec.primary_artists
                      .map((artist) => (
                        <Link key={artist.id} to={`/artist/${artist.id}`}>
                          {artist.name}
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ', ', curr])}
                  </div>
                )}
              {rec.media_type === 'playlist' && rec.song_count && (
                <div>({rec.song_count} songs)</div>
              )}
              <div className="rec-links">
                {rec.spotify_id && (
                  <a
                    href={getSpotifyLink(
                      rec.media_type === 'song' ? 'track' : rec.media_type,
                      rec.spotify_id
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Spotify
                  </a>
                )}
                {rec.youtube_id && (
                  <a
                    href={getYouTubeLink(rec.youtube_id, rec.media_type)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                )}
                {rec.bandcamp_url && (
                  <a
                    href={getBandcampLink(rec.bandcamp_url)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bandcamp
                  </a>
                )}
                {rec.apple_id && (
                  <a
                    href={getAppleMusicLink(rec.media_type, rec.apple_id)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apple Music
                  </a>
                )}
                {(rec.media_type === 'playlist' || rec.media_type === 'mix') &&
                  rec.src_url && (
                    <a
                      href={rec.src_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Source
                    </a>
                  )}
              </div>
              <p>Recommended by: {rec.sender}</p>
              <p>Message: {rec.message}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="grid columns-1">
        <h3>Send a Recommendation</h3>
        <input
          type="text"
          value={friendSearchQuery}
          onChange={(e) => {
            setFriendSearchQuery(e.target.value)
            debouncedFriendSearch(e.target.value)
          }}
          placeholder="Search for a friend..."
        />
        {friendSearchResults.length > 0 && (
          <ul>
            {friendSearchResults.map((friend) => (
              <li
                key={friend.id}
                onClick={() => {
                  setNewRec({
                    ...newRec,
                    username: friend.username,
                  })
                  setFriendSearchQuery(friend.username)
                  setFriendSearchResults([])
                }}
              >
                {friend.username}
              </li>
            ))}
          </ul>
        )}
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value)
            debouncedSearch(e.target.value)
          }}
          placeholder="Search for song, album, playlist, or artist..."
        />
        {searchResults.length > 0 && (
          <ul>
            {searchResults.map((result) => (
              <li
                key={result.id}
                onClick={() => {
                  setNewRec({
                    ...newRec,
                    media_type: result.type,
                    media_id: result.id,
                  })
                  setSearchQuery(result.title)
                  setSearchResults([])
                }}
              >
                {result.title} ({result.type})
              </li>
            ))}
          </ul>
        )}
        <textarea
          value={newRec.message}
          onChange={(e) => setNewRec({ ...newRec, message: e.target.value })}
          placeholder="Message"
        />
        <button onClick={sendRec} className="context-button">
          Send Recommendation
        </button>
      </div>
    </div>
  )
}

export default Recs
