import { services, getPrettyName, login } from '../services/musicServices'

function ServiceSelector({ currentService, handleServiceChange }) {
  return (
    <div>
      {Object.keys(services).map((serviceKey) => (
        <button
          key={serviceKey}
          className="context-button"
          onClick={() => handleServiceChange(serviceKey)}
        >
          {currentService === serviceKey
            ? `Use ${services[serviceKey].prettyName}`
            : `Switch to ${services[serviceKey].prettyName}`}
        </button>
      ))}
      <button className="context-button" onClick={login}>
        Log in to {getPrettyName()}
      </button>
    </div>
  )
}

export default ServiceSelector
