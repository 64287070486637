import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'
import CollageSelectionModal from './CollageSelectionModal'
import { useUserMedia } from './Library/hooks/useUserMedia'

function SongPage() {
  const [song, setSong] = useState(null)
  const { id } = useParams()
  const [showCollageModal, setShowCollageModal] = useState(false)
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    fetchUserMedia,
    userCollection,
  } = useMediaActions('song', id)

  const { userMedia } = useUserMedia()

  useEffect(() => {
    const fetchSong = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}songs/${id}/`)
        setSong(response.data)
      } catch (error) {
        console.error('Error fetching song:', error)
      }
    }

    fetchSong()
  }, [id])

  const handleAddToCollage = (event) => {
    const button = event.currentTarget
    const rect = button.getBoundingClientRect()

    if (showCollageModal) {
      setShowCollageModal(false)
    } else {
      setModalPosition({
        top: rect.bottom,
        left: rect.left,
      })
      setShowCollageModal(true)
    }
  }

  if (!song) return <div>Loading...</div>

  return (
    <div className='media-list'>
      <div className='media-header'>
        <h1 className='metadata-text primary'>{song.title}</h1>
        <div className='media-actions'>
          <MediaActionButton
            onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
            isActive={inQueue}
            isLoading={queueLoading}
            actionType='Queue'
          />
          <MediaActionButton
            onClick={() => handleCollectionAction(inCollection)}
            isActive={inCollection}
            isLoading={collectionLoading}
            actionType='Collection'
          />
          <MediaActionButton
            onClick={handleAddToCollage}
            isActive={false}
            isLoading={false}
            actionType='Collage'
          />
        </div>
      </div>

      <div className='media-info flex flex-col items-center'>
        <div className='info-line flex items-center gap-1'>
          <span className='metadata-text secondary'>Artists:</span>
          {song.primary_artists
            .map((artist) => (
              <Link
                key={artist.id}
                to={`/artist/${artist.id}`}
                className='metadata-text primary interactive'
              >
                {artist.name}
              </Link>
            ))
            .reduce((prev, curr) => [prev, ', ', curr])}
        </div>

        {song.duration && (
          <div className='info-line flex items-center gap-1'>
            <span className='metadata-text secondary'>Duration:</span>
            <span className='metadata-text primary'>{song.duration}</span>
          </div>
        )}

        {song.album && (
          <div className='info-line flex items-center gap-1'>
            <span className='metadata-text secondary'>Album:</span>
            <Link to={`/album/${song.album.id}`} className='metadata-text primary interactive'>
              {song.album.title}
            </Link>
          </div>
        )}
      </div>

      <div className='service-links flex flex-col items-center gap-2'>
        <span className='metadata-text secondary'>Listen on:</span>
        <div className='flex items-center gap-2'>
          {[
            song.spotify_id && (
              <a
                key='spotify'
                href={`https://open.spotify.com/track/${song.spotify_id}`}
                target='_blank'
                rel='noopener noreferrer'
                className='service-link'
              >
                Spotify
              </a>
            ),
            song.apple_id && (
              <a
                key='apple'
                href={`https://music.apple.com/us/song/${song.apple_id}`}
                target='_blank'
                rel='noopener noreferrer'
                className='service-link'
              >
                Apple Music
              </a>
            ),
            song.youtube_id && (
              <a
                key='youtube'
                href={`https://www.youtube.com/watch?v=${song.youtube_id}`}
                target='_blank'
                rel='noopener noreferrer'
                className='service-link'
              >
                YouTube
              </a>
            ),
            song.bandcamp_url && (
              <a
                key='bandcamp'
                href={song.bandcamp_url}
                target='_blank'
                rel='noopener noreferrer'
                className='service-link'
              >
                Bandcamp
              </a>
            ),
          ]
            .filter(Boolean)
            .reduce(
              (prev, curr, idx) =>
                idx === 0 ? [curr] : [...prev, <span className='separator'> • </span>, curr],
              []
            )}
        </div>
      </div>

      {showCollageModal && (
        <CollageSelectionModal
          position={modalPosition}
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={{ type: 'song', id: parseInt(id) }}
          fetchUserMedia={fetchUserMedia}
          collages={userMedia?.collection?.collages || []}
        />
      )}
    </div>
  )
}

export default SongPage
