import { useState, useEffect } from 'react'
import {
  handleSelect,
  handleInfo,
  handleAddToQueue,
} from '../utils/mediaHandlers'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'

function SearchResults({ results, currentService, playing, playPreview }) {
  const [userCollection, setUserCollection] = useState([])
  const [userQueue, setUserQueue] = useState([])
  const [loadingItems, setLoadingItems] = useState({})

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const [collectionResponse, queueResponse] = await Promise.all([
          axios.get(`${API_URL}user-media/`),
          axios.get(`${API_URL}user-queue/`),
        ])
        setUserCollection(collectionResponse.data)
        setUserQueue(queueResponse.data)
        console.log('Fetched user collection:', collectionResponse.data)
        console.log('Fetched user queue:', queueResponse.data)
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    fetchUserData()
  }, [])

  const isInCollection = (item) => {
    return userCollection.some((collectionItem) => {
      if (item.type !== collectionItem.media_type) return false

      // Check by service-specific IDs
      return (
        collectionItem.spotify_id === item.id ||
        collectionItem.apple_id === item.id ||
        (item.type === 'playlist' && collectionItem.src_url?.includes(item.id))
      )
    })
  }

  const isInQueue = (item) => {
    return userQueue.some((queueItem) => {
      if (item.type !== queueItem.media_type) return false

      // Check by service-specific IDs
      return (
        queueItem.spotify_id === item.id ||
        queueItem.apple_id === item.id ||
        (item.type === 'playlist' && queueItem.src_url?.includes(item.id))
      )
    })
  }

  const handleCollectionClick = async (item) => {
    console.log(`Handling collection click for item:`, item)
    setLoadingItems((prev) => ({ ...prev, [item.id]: true }))
    try {
      if (isInCollection(item)) {
        const collectionItem = userCollection.find((media) => {
          if (item.type !== media.media_type) return false
          return (
            media.spotify_id === item.id ||
            media.apple_id === item.id ||
            (item.type === 'playlist' && media.src_url?.includes(item.id))
          )
        })
        if (collectionItem) {
          await axios.delete(
            `${API_URL}user-media/${item.type}/${collectionItem.id}/`
          )
        }
      } else {
        await handleSelect(item, currentService)
      }
      const collectionResponse = await axios.get(`${API_URL}user-media/`)
      setUserCollection(collectionResponse.data)
    } catch (error) {
      console.error('Error handling collection click:', error)
    } finally {
      setLoadingItems((prev) => ({ ...prev, [item.id]: false }))
    }
  }

  const handleQueueClick = async (item) => {
    setLoadingItems((prev) => ({ ...prev, [item.id]: true }))
    try {
      if (isInQueue(item)) {
        const queueItem = userQueue.find(
          (queueItem) =>
            queueItem.media_type === item.type &&
            (queueItem.spotify_id === item.id || queueItem.apple_id === item.id)
        )
        if (queueItem) {
          await axios.delete(`${API_URL}queue/remove/${queueItem.queue_id}/`)
        }
      } else {
        await handleAddToQueue(item, currentService)
      }
      const queueResponse = await axios.get(`${API_URL}user-queue/`)
      setUserQueue(queueResponse.data)
    } catch (error) {
      console.error('Error handling queue click:', error)
    } finally {
      setLoadingItems((prev) => ({ ...prev, [item.id]: false }))
    }
  }

  return (
    <>
      {results.tracks && (
        <div>
          <h3>Tracks</h3>
          <ul>
            {results.tracks.map((item) => (
              <li key={item.id}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  {item.album.images.length > 0 && (
                    <img
                      src={item.album.images[item.album.images.length - 1].url}
                      alt={`${item.album.name} cover`}
                      style={{
                        width: '40px',
                        height: '40px',
                        marginRight: '10px',
                      }}
                    />
                  )}
                  {item.preview_url && (
                    <button
                      onClick={() => playPreview(item.preview_url, item.id)}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'rgba(0,0,0,0.5)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      {playing === item.id ? '⏸' : '▶'}
                    </button>
                  )}
                </div>
                <span>
                  {item.name} by{' '}
                  {item.artists.map((artist) => artist.name).join(', ')}
                </span>
                <button
                  onClick={() =>
                    handleCollectionClick({ ...item, type: 'song' })
                  }
                  className={
                    isInCollection({ ...item, type: 'song' })
                      ? 'remove-button'
                      : 'add-button'
                  }
                  disabled={loadingItems[item.id]}
                >
                  {loadingItems[item.id]
                    ? 'Loading...'
                    : isInCollection({ ...item, type: 'song' })
                      ? '- Collection'
                      : '+ Collection'}
                </button>
                <button
                  onClick={() => handleQueueClick({ ...item, type: 'song' })}
                  className={
                    isInQueue({ ...item, type: 'song' })
                      ? 'remove-button'
                      : 'add-button'
                  }
                  disabled={loadingItems[item.id]}
                >
                  {loadingItems[item.id]
                    ? 'Loading...'
                    : isInQueue({ ...item, type: 'song' })
                      ? '- Queue'
                      : '+ Queue'}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {results.albums && (
        <div>
          <h3>Albums</h3>
          <ul>
            {results.albums.map((item) => (
              <li key={item.id}>
                {item.images.length > 0 && (
                  <img
                    src={item.images[item.images.length - 1].url}
                    alt={`${item.name} cover`}
                    style={{
                      width: '40px',
                      height: '40px',
                      marginRight: '10px',
                    }}
                  />
                )}
                <span>
                  {item.name} by{' '}
                  {item.artists.map((artist) => artist.name).join(', ')} (from{' '}
                  {currentService})
                </span>
                <button
                  onClick={() =>
                    handleCollectionClick({ ...item, type: 'album' })
                  }
                  className={
                    isInCollection({ ...item, type: 'album' })
                      ? 'remove-button'
                      : 'add-button'
                  }
                  disabled={loadingItems[item.id]}
                >
                  {loadingItems[item.id]
                    ? 'Loading...'
                    : isInCollection({ ...item, type: 'album' })
                      ? '- Collection'
                      : '+ Collection'}
                </button>
                <button
                  onClick={() => handleQueueClick({ ...item, type: 'album' })}
                  className={
                    isInQueue({ ...item, type: 'album' })
                      ? 'remove-button'
                      : 'add-button'
                  }
                  disabled={loadingItems[item.id]}
                >
                  {loadingItems[item.id]
                    ? 'Loading...'
                    : isInQueue({ ...item, type: 'album' })
                      ? '- Queue'
                      : '+ Queue'}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {results.playlists && (
        <div>
          <h3>Playlists</h3>
          <ul>
            {results.playlists.map((item) => (
              <li
                key={item.id}
                onClick={() =>
                  handleCollectionClick({ ...item, type: 'playlist' })
                }
              >
                {item.images.length > 0 && (
                  <img
                    src={item.images[item.images.length - 1].url}
                    alt={`${item.name} cover`}
                    style={{
                      width: '40px',
                      height: '40px',
                      marginRight: '10px',
                    }}
                  />
                )}
                {item.name} by {item.owner.display_name} (from {currentService})
                <button
                  onClick={() =>
                    handleQueueClick({ ...item, type: 'playlist' })
                  }
                  className={
                    isInQueue({ ...item, type: 'playlist' })
                      ? 'remove-button'
                      : 'add-button'
                  }
                  disabled={loadingItems[item.id]}
                >
                  {loadingItems[item.id]
                    ? 'Loading...'
                    : isInQueue({ ...item, type: 'playlist' })
                      ? '- Queue'
                      : '+ Queue'}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default SearchResults
