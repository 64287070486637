const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true'

const API_URL = DEV_MODE
  ? 'http://localhost:8000/api/'
  : 'https://ctx.rashomon.blue/api/'

const BASE_URL = DEV_MODE
  ? 'http://localhost:8000'
  : 'https://ctx.rashomon.blue'

export { API_URL, BASE_URL }
export default API_URL
