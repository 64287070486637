import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'

function ArtistPage() {
  const [artist, setArtist] = useState(null)
  const { id } = useParams()

  useEffect(() => {
    const fetchArtist = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}artists/${id}/`)
        setArtist(response.data)
      } catch (error) {
        console.error('Error fetching artist:', error)
      }
    }

    fetchArtist()
  }, [id])

  if (!artist) return <div>Loading...</div>

  return (
    <div>
      <h1>{artist.name}</h1>

      <h2>Songs:</h2>
      <ul>
        {artist.songs.map((song) => (
          <li key={song.id}>
            <Link to={`/song/${song.id}`}>{song.title}</Link>
          </li>
        ))}
      </ul>

      <h2>Albums:</h2>
      <ul>
        {artist.albums.map((album) => (
          <li key={album.id}>
            <Link to={`/album/${album.id}`}>{album.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ArtistPage
