import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'
import CollageSelectionModal from './CollageSelectionModal'
import { useUserMedia } from './Library/hooks/useUserMedia'

function PlaylistPage() {
  const [playlist, setPlaylist] = useState(null)
  const { id } = useParams()
  const [trackLoadingStatus, setTrackLoadingStatus] = useState({})
  const [showCollageModal, setShowCollageModal] = useState(false)
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
  const [selectedMediaForCollage, setSelectedMediaForCollage] = useState({
    type: 'playlist',
    id: parseInt(id),
  })

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    fetchUserMedia,
    itemCollectionStatus,
    userCollection,
  } = useMediaActions('playlist', id, playlist?.songs || [])

  const { userMedia } = useUserMedia()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const playlistResponse = await axios.get(`${API_URL}playlists/${id}/`)
        setPlaylist(playlistResponse.data)

        const loadingStatus = {}
        playlistResponse.data.songs.forEach((song) => {
          loadingStatus[song.id] = false
        })
        setTrackLoadingStatus(loadingStatus)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [id])

  const handleitemCollectionAction = async (trackId, isRemoving) => {
    try {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: true }))
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/song/${trackId}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: 'song',
          id: trackId,
        })
      }
      await fetchUserMedia()
    } catch (error) {
      console.error(`Error ${isRemoving ? 'removing' : 'adding'} track to collection:`, error)
    } finally {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: false }))
    }
  }

  const renderListenLinks = (item) => {
    const links = []

    // If it's the playlist itself (has src_url) and is external
    if (item.src_url && item.is_external) {
      let serviceName = 'Source'
      if (item.src_url.includes('spotify.com')) {
        serviceName = 'Spotify'
      } else if (item.src_url.includes('music.apple.com')) {
        serviceName = 'Apple Music'
      } else if (item.src_url.includes('youtube.com')) {
        serviceName = 'YouTube'
      } else if (item.src_url.includes('bandcamp.com')) {
        serviceName = 'Bandcamp'
      }

      links.push(
        <a
          key='source'
          href={item.src_url}
          target='_blank'
          rel='noopener noreferrer'
          className='service-link'
        >
          {serviceName}
        </a>
      )
    } else {
      // Handle individual songs as before
      if (item.spotify_id)
        links.push(
          <a
            key='spotify'
            href={`https://open.spotify.com/track/${item.spotify_id}`}
            target='_blank'
            rel='noopener noreferrer'
            className='service-link'
          >
            Spotify
          </a>
        )
      if (item.apple_id)
        links.push(
          <a
            key='apple'
            href={`https://music.apple.com/us/song/${item.apple_id}`}
            target='_blank'
            rel='noopener noreferrer'
            className='service-link'
          >
            Apple Music
          </a>
        )
      if (item.bandcamp_url)
        links.push(
          <a
            key='bandcamp'
            href={item.bandcamp_url}
            target='_blank'
            rel='noopener noreferrer'
            className='service-link'
          >
            Bandcamp
          </a>
        )
      if (item.youtube_id)
        links.push(
          <a
            key='youtube'
            href={`https://www.youtube.com/watch?v=${item.youtube_id}`}
            target='_blank'
            rel='noopener noreferrer'
            className='service-link'
          >
            YouTube
          </a>
        )
    }

    return links.length > 0 ? (
      <div className='service-links'>
        {links.reduce(
          (prev, curr, idx) =>
            idx === 0
              ? [curr]
              : [
                  ...prev,
                  <span key={`separator-${idx}`} className='separator'>
                    {' '}
                    •{' '}
                  </span>,
                  curr,
                ],
          []
        )}
      </div>
    ) : (
      <span className='metadata-text secondary'>No links available</span>
    )
  }

  const handleAddToCollage = (event, mediaType = 'playlist', mediaId = id) => {
    const button = event.currentTarget
    const rect = button.getBoundingClientRect()

    // Toggle the modal
    if (showCollageModal) {
      setShowCollageModal(false)
    } else {
      setModalPosition({
        top: rect.bottom,
        left: rect.left,
      })
      setShowCollageModal(true)
    }
    // Store the selected media info
    setSelectedMediaForCollage({ type: mediaType, id: parseInt(mediaId) })
  }

  if (!playlist) return <div>Loading...</div>

  return (
    <div className='media-list'>
      <div className='media-header'>
        <h1 className='metadata-text primary'>{playlist.title}</h1>
        <div className='media-actions'>
          <MediaActionButton
            onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
            isActive={inQueue}
            isLoading={queueLoading}
            actionType='Queue'
          />
          <MediaActionButton
            onClick={() => handleCollectionAction(inCollection)}
            isActive={inCollection}
            isLoading={collectionLoading}
            actionType='Collection'
          />
          <MediaActionButton
            onClick={handleAddToCollage}
            isActive={false}
            isLoading={false}
            actionType='Collage'
          />
        </div>
      </div>

      <div className='metadata'>
        <div className='info-line'>
          <span className='metadata-text secondary'>Songs: {playlist.songs.length}</span>
        </div>
        <div className='service-links center'>{renderListenLinks(playlist)}</div>
      </div>

      <div className='mt-5'>
        <ul className='left-justify'>
          {playlist.songs.map((song) => (
            <li key={song.id}>
              <div className='metadata'>
                <Link to={`/song/${song.id}`} className='metadata-text primary interactive'>
                  {song.title}
                </Link>
                {song.primary_artists && (
                  <>
                    <span className='metadata-text secondary'> by </span>
                    {song.primary_artists
                      .map((artist) => (
                        <Link
                          key={artist.id}
                          to={`/artist/${artist.id}`}
                          className='metadata-text primary interactive'
                        >
                          {artist.name}
                        </Link>
                      ))
                      .reduce((prev, curr) => [prev, ', ', curr])}
                  </>
                )}
                <div className='service-links'>{renderListenLinks(song)}</div>
              </div>
              <div className='media-actions'>
                <MediaActionButton
                  onClick={() => handleitemCollectionAction(song.id, itemCollectionStatus[song.id])}
                  isActive={itemCollectionStatus[song.id]}
                  isLoading={trackLoadingStatus[song.id]}
                  actionType='Collection'
                />
                <MediaActionButton
                  onClick={(event) => handleAddToCollage(event, 'song', song.id)}
                  isActive={false}
                  isLoading={false}
                  actionType='Collage'
                />
              </div>
            </li>
          ))}
        </ul>
      </div>

      {showCollageModal && (
        <CollageSelectionModal
          position={modalPosition}
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={selectedMediaForCollage}
          fetchUserMedia={fetchUserMedia}
          collages={userMedia?.collection?.collages || []}
        />
      )}
    </div>
  )
}

export default PlaylistPage
