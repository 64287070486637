import { Link } from 'react-router-dom'
import MediaActionButton from '../../common/MediaActionButton'
import { getServiceLinks, renderServiceLinksWithSeparators } from '../utils/serviceLinks'

function MediaList({
  mediaItems,
  onRemoveFromCollection,
  onAddToCollage,
  type,
  removeLoadingStates = {},
}) {
  const renderArtists = (item) => {
    if (!item.primary_artists || type === 'playlist' || type === 'mix') {
      return null
    }

    return (
      <>
        <span className='metadata-text secondary'> by </span>
        {item.primary_artists
          .map((artist) => (
            <Link
              key={artist.id}
              to={`/artist/${artist.id}`}
              className='metadata-text primary interactive'
            >
              {artist.name}
            </Link>
          ))
          .reduce((prev, curr) => [prev, ', ', curr])}
      </>
    )
  }

  const renderMetadata = (item) => {
    if (type === 'playlist' && item.song_count) {
      return <span className='metadata-text secondary'> ({item.song_count} songs)</span>
    }
    return null
  }

  return (
    <div className='centered-list'>
      <ul className='left-justify'>
        {mediaItems
          .sort((a, b) => new Date(b.added_at) - new Date(a.added_at))
          .filter((item) => type !== 'album' || item.direct_add)
          .map((item) => (
            <li key={item.id}>
              <div className='metadata'>
                <Link to={`/${type}/${item.id}`} className='metadata-text primary interactive'>
                  {item.title}
                </Link>
                {renderArtists(item)}
                {renderMetadata(item)}
                {type !== 'collage' && (
                  <div className='service-links'>
                    {renderServiceLinksWithSeparators(getServiceLinks(item, type))}
                  </div>
                )}
              </div>
              <div className='media-actions'>
                <MediaActionButton
                  onClick={(event) => onAddToCollage(type, item.id, event)}
                  isActive={false}
                  isLoading={false}
                  actionType='Collage'
                />
                <MediaActionButton
                  onClick={() => onRemoveFromCollection(type, item.id)}
                  isActive={false}
                  isLoading={removeLoadingStates[item.id]}
                  actionType='Remove'
                />
              </div>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default MediaList
