import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import API_URL from '../config'

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

const login = async (username, password) => {
  console.log('Attempting login with API URL:', API_URL)
  try {
    const response = await axios.post(API_URL + 'token/', {
      username,
      password,
    })
    console.log('Login response:', response.data)
    if (response.data.access) {
      localStorage.setItem('token', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      setAuthToken(response.data.access)
      setupTokenRefresh()
    }
    return response.data
  } catch (error) {
    console.error(
      'Login error:',
      error.response ? error.response.data : error.message
    )
    console.error('Full error object:', error)
    throw error
  }
}

const logout = () => {
  localStorage.removeItem('token')
  setAuthToken(null)
}

const isAuthenticated = () => {
  const token = localStorage.getItem('token')
  if (token) {
    const decodedToken = jwtDecode(token)
    return decodedToken.exp * 1000 > Date.now()
  }
  return false
}

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  if (refreshToken) {
    try {
      const response = await axios.post(API_URL + 'token/refresh/', {
        refresh: refreshToken,
      })
      if (response.data.access) {
        localStorage.setItem('token', response.data.access)
        setAuthToken(response.data.access)
      }
      return response.data
    } catch (error) {
      console.error('Token refresh failed:', error)
      logout()
      throw error
    }
  }
}

const setupTokenRefresh = () => {
  setInterval(
    async () => {
      if (isAuthenticated()) {
        await refreshToken()
      }
    },
    6 * 60 * 60 * 1000
  ) // Refresh every 6 hours
}

export {
  login,
  logout,
  isAuthenticated,
  setAuthToken,
  refreshToken,
  setupTokenRefresh,
}
