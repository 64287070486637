import axios from 'axios'
import API_URL from '../config'
import { useNavigate } from 'react-router-dom'

function CollageSelectionModal({ collages, onClose, selectedMediaForCollage, fetchUserMedia }) {
  const navigate = useNavigate()

  const handleSelect = async (collageId) => {
    try {
      await axios.post(`${API_URL}collages/${collageId}/add/`, {
        media_type: selectedMediaForCollage.type,
        media_id: selectedMediaForCollage.id,
      })
      onClose()
      fetchUserMedia()
    } catch (error) {
      console.error('Error adding to collage:', error)
    }
  }

  return (
    <div
      className='fixed inset-0 flex items-center justify-center'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div style={{ backgroundColor: 'white' }} className='w-96 rounded-lg p-6'>
        <div className='mb-4 flex items-center justify-between'>
          <h3 className='text-lg font-bold'>Add to Collage</h3>
          <button onClick={onClose} className='text-gray-500 hover:text-gray-700'>
            ×
          </button>
        </div>
        <ul className='mb-4'>
          {collages.map((collage) => (
            <li key={collage.id} className='mb-2'>
              <button
                onClick={() => handleSelect(collage.id)}
                className='w-full text-left hover:underline'
              >
                {collage.title}
              </button>
            </li>
          ))}
        </ul>
        <div className='border-t pt-4'>
          <button
            onClick={() => navigate('/collage/create')}
            className='context-button w-full py-1 text-sm'
          >
            Create New Collage
          </button>
        </div>
      </div>
    </div>
  )
}

export default CollageSelectionModal
