const HeroPage = () => {
  return (
    <div className='bg-contextWhite'>
      <div className='container flex justify-center pt-24'>
        <img className='h-auto w-full max-w-[400px] px-4' src='/lettermark.svg' alt='Context' />
      </div>
    </div>
  )
}

export default HeroPage
