import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import { addToQueue, removeFromQueue, checkMediaStatus } from '../services/mediaService'
import API_URL from '../config'
import { useUserMedia } from '../components/Library/hooks/useUserMedia'

const useMediaActions = (mediaType, mediaId, items = [], isCollage = false) => {
  const { userMedia, fetchUserMedia } = useUserMedia()
  const [inQueue, setInQueue] = useState(false)
  const [inCollection, setInCollection] = useState(false)
  const [queueLoading, setQueueLoading] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)
  const [queueItemId, setQueueItemId] = useState(null)
  const [itemCollectionStatus, setItemCollectionStatus] = useState({})
  const [itemLoadingStatus, setItemLoadingStatus] = useState({})
  const [itemQueueStatus, setItemQueueStatus] = useState({})
  const [itemQueueLoading, setItemQueueLoading] = useState({})
  const [isPartial, setIsPartial] = useState(false)

  useEffect(() => {
    if (items.length > 0 && userMedia.collection) {
      if (isCollage) {
        const collectionStatus = {}
        items.forEach((item) => {
          collectionStatus[item.id] = userMedia.collection[pluralMap[item.type]]?.some(
            (media) => media.id === item.id
          )
        })
        setItemCollectionStatus(collectionStatus)
      } else {
        const newTrackStatus = {}
        items.forEach((track) => {
          const trackId = track.id
          newTrackStatus[trackId] = userMedia.collection.songs?.some((item) => item.id === trackId)
        })
        setItemCollectionStatus(newTrackStatus)

        const albumInCollection = userMedia.collection[pluralMap[mediaType]]?.find(
          (a) => a.id === parseInt(mediaId)
        )

        setInCollection(!!albumInCollection)

        setIsPartial(albumInCollection && albumInCollection.direct_add === false)
      }
    }
  }, [items, userMedia, isCollage, mediaType, mediaId])

  // Initial fetch of user media and status check
  useEffect(() => {
    if (mediaId) {
      fetchUserMedia()
      checkMediaStatus(mediaType, mediaId)
        .then((response) => {
          setInQueue(response.in_queue)
          setInCollection(response.in_collection)
          if (response.queue_id) {
            setQueueItemId(response.queue_id)
          }
        })
        .catch((error) => {
          console.error('Error checking media status:', error)
        })
    }
  }, [mediaId, mediaType, fetchUserMedia])

  const handleCollectionAction = async (isRemoving = false) => {
    try {
      setCollectionLoading(true)
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/${mediaType}/${mediaId}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: mediaType,
          id: mediaId,
        })
      }
      await fetchUserMedia()
      const response = await checkMediaStatus(mediaType, mediaId)
      setInCollection(response.in_collection)
    } catch (error) {
      console.error(`Error ${isRemoving ? 'removing from' : 'adding to'} collection:`, error)
    } finally {
      setCollectionLoading(false)
    }
  }

  const fetchQueueStatus = useCallback(async () => {
    try {
      const queueResponse = await axios.get(`${API_URL}user-queue/`)
      const queueData = queueResponse.data

      if (isCollage && items.length > 0) {
        const queueStatus = {}
        items.forEach((item) => {
          queueStatus[item.id] = queueData.some(
            (queueItem) => queueItem.media_type === item.type && queueItem.id === item.id
          )
        })
        setItemQueueStatus(queueStatus)
      }
    } catch (error) {
      console.error('Error fetching queue status:', error)
    }
  }, [isCollage, items])

  const handleAddToQueue = async (itemType, itemId) => {
    try {
      // If we're handling a specific item in a collage
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: true }))
        await addToQueue(itemType, itemId)
        await fetchQueueStatus()
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      }
      // If we're handling the main media object (album, playlist, etc.)
      else {
        setQueueLoading(true)
        await addToQueue(mediaType, mediaId)
        const response = await checkMediaStatus(mediaType, mediaId)
        setInQueue(response.in_queue)
        setInCollection(response.in_collection)
        if (response.queue_id) {
          setQueueItemId(response.queue_id)
        }
        setQueueLoading(false)
      }
    } catch (error) {
      console.error('Error adding to queue:', error)
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      } else {
        setQueueLoading(false)
      }
    }
  }

  const handleRemoveFromQueue = async (itemType, itemId) => {
    try {
      // If we're handling a specific item in a collage
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: true }))
        const queueResponse = await axios.get(`${API_URL}user-queue/`)
        const queueItem = queueResponse.data.find(
          (item) => item.media_type === itemType && item.id === itemId
        )
        if (queueItem) {
          await removeFromQueue(queueItem.queue_id)
        }
        await fetchQueueStatus()
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      }
      // If we're handling the main media object (album, playlist, etc.)
      else {
        setQueueLoading(true)
        if (queueItemId) {
          await removeFromQueue(queueItemId)
          const response = await checkMediaStatus(mediaType, mediaId)
          setInQueue(response.in_queue)
          setInCollection(response.in_collection)
          if (response.queue_id) {
            setQueueItemId(response.queue_id)
          }
        }
        setQueueLoading(false)
      }
    } catch (error) {
      console.error('Error removing from queue:', error)
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      } else {
        setQueueLoading(false)
      }
    }
  }

  const handleItemCollectionAction = async (item, isRemoving = false) => {
    try {
      setItemLoadingStatus((prev) => ({ ...prev, [item.id]: true }))
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/${item.type}/${item.id}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: item.type,
          id: item.id,
        })
      }
      await fetchUserMedia()
    } catch (error) {
      console.error(`Error ${isRemoving ? 'removing from' : 'adding to'} collection:`, error)
    } finally {
      setItemLoadingStatus((prev) => ({ ...prev, [item.id]: false }))
    }
  }

  useEffect(() => {
    if (isCollage && items.length > 0) {
      fetchQueueStatus()
    }
  }, [items, isCollage, fetchQueueStatus])

  const pluralMap = {
    song: 'songs',
    album: 'albums',
    playlist: 'playlists',
    mix: 'mixes',
    collage: 'collages',
  }

  return {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    handleItemCollectionAction,
    fetchUserMedia,
    itemCollectionStatus,
    itemLoadingStatus,
    itemQueueStatus,
    itemQueueLoading,
    userMedia,
    isPartial,
  }
}

export default useMediaActions
