import { useState, useEffect } from 'react'

const LoadingEllipsis = ({ loading = true }) => {
  const [dots, setDots] = useState('')

  useEffect(() => {
    if (!loading) {
      setDots('...')
      return
    }

    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? '' : prev + '.'))
    }, 500)

    return () => clearInterval(interval)
  }, [loading])

  return <span>{dots}</span>
}

export default LoadingEllipsis
