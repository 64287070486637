import { createContext, useState, useContext } from 'react'

const NotificationContext = createContext()

export const useNotification = () => useContext(NotificationContext)

export const NotificationProvider = ({ children }) => {
  const [hasNewRecs, setHasNewRecs] = useState(false)

  return (
    <NotificationContext.Provider value={{ hasNewRecs, setHasNewRecs }}>
      {children}
    </NotificationContext.Provider>
  )
}
