import { useState, useRef, useEffect, useCallback } from 'react'
import axios from 'axios'
import { setAuthToken } from '../../../services/auth'
import API_URL from '../../../config'

export function useUserMedia() {
  const [userMedia, setUserMedia] = useState({
    collection: {
      songs: [],
      albums: [],
      playlists: [],
      mixes: [],
      collages: [],
    },
    queue: [],
  })
  const [isLoading, setIsLoading] = useState(true)
  const abortControllerRef = useRef(null)
  const requestInProgressRef = useRef(false)

  const fetchUserMedia = useCallback(async (force = false) => {
    // Don't fetch if a request is already in progress unless forced
    if (requestInProgressRef.current && !force) {
      return
    }

    requestInProgressRef.current = true
    setIsLoading(true)

    try {
      setAuthToken(localStorage.getItem('token'))
      const [collectionResponse, queueResponse] = await Promise.all([
        axios.get(`${API_URL}user-media/`),
        axios.get(`${API_URL}user-queue/`),
      ])

      const categorizedMedia = {
        collection: {
          songs: [],
          albums: [],
          playlists: [],
          mixes: [],
          collages: [],
        },
        queue: queueResponse.data,
      }

      const pluralMap = {
        song: 'songs',
        album: 'albums',
        playlist: 'playlists',
        mix: 'mixes',
        collage: 'collages',
      }

      collectionResponse.data.forEach((item) => {
        const collectionKey = pluralMap[item.media_type]
        if (categorizedMedia.collection[collectionKey]) {
          categorizedMedia.collection[collectionKey].push(item)
        }
      })

      setUserMedia(categorizedMedia)
    } catch (error) {
      console.error('Error fetching user media:', error)
    } finally {
      requestInProgressRef.current = false
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUserMedia()
  }, [fetchUserMedia])

  return { userMedia, isLoading, fetchUserMedia }
}
