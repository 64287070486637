function LibraryNavigation({
  currentSection,
  showSection,
  currentCollectionSection,
  setCurrentCollectionSection,
  showSearch,
  setShowSearch,
  onSearch,
}) {
  return (
    <div>
      <nav className='library-nav'>
        <button
          onClick={() => showSection('queue')}
          className={currentSection === 'queue' ? 'active' : ''}
        >
          queue
        </button>
        <button
          onClick={() => showSection('collection')}
          className={currentSection === 'collection' ? 'active' : ''}
        >
          collection
        </button>
        <button
          onClick={() => {
            setShowSearch(!showSearch)
            if (!showSearch) {
              showSection('search')
            }
          }}
          className={showSearch ? 'active' : ''}
        >
          search
        </button>
      </nav>

      {showSearch && (
        <div className='search-container'>
          <input
            type='text'
            placeholder='Search your library...'
            onChange={(e) => onSearch(e.target.value)}
            className='search-input'
            autoFocus
          />
        </div>
      )}

      {currentSection === 'collection' && !showSearch && (
        <nav className='collection-nav'>
          <button
            onClick={() => setCurrentCollectionSection('songs')}
            className={currentCollectionSection === 'songs' ? 'active' : ''}
          >
            songs
          </button>
          <button
            onClick={() => setCurrentCollectionSection('albums')}
            className={currentCollectionSection === 'albums' ? 'active' : ''}
          >
            albums
          </button>
          <button
            onClick={() => setCurrentCollectionSection('playlists')}
            className={currentCollectionSection === 'playlists' ? 'active' : ''}
          >
            playlists
          </button>
          <button
            onClick={() => setCurrentCollectionSection('mixes')}
            className={currentCollectionSection === 'mixes' ? 'active' : ''}
          >
            mixes
          </button>
          <button
            onClick={() => setCurrentCollectionSection('collages')}
            className={currentCollectionSection === 'collages' ? 'active' : ''}
          >
            collages
          </button>
        </nav>
      )}
    </div>
  )
}

export default LibraryNavigation
