import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import CollageForm from './CollageForm'
import useMediaActions from '../hooks/useMediaActions'
import MediaActionButton from './common/MediaActionButton'

function CollagePage() {
  const [collage, setCollage] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [items, setItems] = useState([])
  const { id } = useParams()

  const {
    itemCollectionStatus,
    itemLoadingStatus,
    handleItemCollectionAction,
    fetchUserMedia,
    handleAddToQueue,
    handleRemoveFromQueue,
    itemQueueStatus,
    itemQueueLoading,
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleCollectionAction,
  } = useMediaActions('collage', id, items, true)

  useEffect(() => {
    const fetchCollage = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}collages/${id}/`)
        setCollage(response.data)
        setItems(response.data.items)
      } catch (error) {
        console.error('Error fetching collage:', error)
      }
    }

    fetchCollage()
  }, [id])

  const handleRemoveFromCollage = async (type, itemId) => {
    try {
      await axios.post(`${API_URL}collages/${id}/remove/`, {
        media_type: type,
        media_id: itemId,
      })
      setItems((prevItems) => prevItems.filter((item) => item.id !== itemId))
    } catch (error) {
      console.error('Error removing item from collage:', error)
    }
  }

  const moveItem = async (index, direction) => {
    const newItems = [...items]
    const item = newItems[index]
    newItems.splice(index, 1)
    newItems.splice(index + direction, 0, item)
    setItems(newItems)
  }

  const handleSaveCollage = async (formData) => {
    try {
      await axios.patch(`${API_URL}collages/${id}/update/`, {
        ...formData,
        items: items.map((item, index) => ({
          id: item.id,
          type: item.type,
          order: index,
        })),
      })
      setCollage((prev) => ({ ...prev, ...formData }))
      setIsEditing(false)
      const response = await axios.get(`${API_URL}collages/${id}/`)
      setCollage(response.data)
      setItems(response.data.items)
    } catch (error) {
      console.error('Error updating collage:', error)
    }
  }

  if (!collage) return <div>Loading...</div>

  const getItemLink = (item) => {
    switch (item.type) {
      case 'song':
        return `/song/${item.id}`
      case 'album':
        return `/album/${item.id}`
      case 'playlist':
        return `/playlist/${item.id}`
      case 'mix':
        return `/mix/${item.id}`
      case 'collage':
        return `/collage/${item.id}`
      default:
        return '#'
    }
  }

  const renderItemDetails = (item, index) => {
    return (
      <>
        <Link to={getItemLink(item)}>{item.title}</Link>
        {item.primary_artists && (
          <span>
            {' '}
            by{' '}
            {item.primary_artists
              .map((artist) => (
                <Link key={artist.id} to={`/artist/${artist.id}`}>
                  {artist.name}
                </Link>
              ))
              .reduce((prev, curr) => [prev, ', ', curr])}
          </span>
        )}
        <span> ({item.type})</span>{' '}
        {!isEditing && (
          <>
            <MediaActionButton
              onClick={() =>
                itemQueueStatus[item.id]
                  ? handleRemoveFromQueue(item.type, item.id)
                  : handleAddToQueue(item.type, item.id)
              }
              isActive={itemQueueStatus[item.id]}
              isLoading={itemQueueLoading[item.id]}
              actionType="Queue"
            />
            <MediaActionButton
              onClick={() =>
                itemCollectionStatus[item.id]
                  ? handleItemCollectionAction(item, true)
                  : handleItemCollectionAction(item, false)
              }
              isActive={itemCollectionStatus[item.id]}
              isLoading={itemLoadingStatus[item.id]}
              actionType="Collection"
            />
            <button
              onClick={() => handleRemoveFromCollage(item.type, item.id)}
              className="remove-button"
            >
              X
            </button>
          </>
        )}
        {isEditing && (
          <>
            <button onClick={() => moveItem(index, -1)} disabled={index === 0}>
              ↑
            </button>
            <button
              onClick={() => moveItem(index, 1)}
              disabled={index === items.length - 1}
            >
              ↓
            </button>
          </>
        )}
      </>
    )
  }

  return (
    <div>
      {isEditing ? (
        <CollageForm
          collage={collage}
          onSubmit={handleSaveCollage}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          <h1>{collage.title}</h1>
          {collage.description && <p>{collage.description}</p>}
          <div className="action-buttons">
            <MediaActionButton
              onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
              isActive={inQueue}
              isLoading={queueLoading}
              actionType="Queue"
            />
            <MediaActionButton
              onClick={() => handleCollectionAction(inCollection)}
              isActive={inCollection}
              isLoading={collectionLoading}
              actionType="Collection"
            />
            <button onClick={() => setIsEditing(true)}>Edit</button>
          </div>
        </>
      )}
      <h2>Items:</h2>
      <ol>
        {items.map((item, index) => (
          <li key={`${item.type}-${item.id}`}>
            {renderItemDetails(item, index)}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default CollagePage
