import { useState, useEffect } from 'react'

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import { isAuthenticated, logout, setupTokenRefresh, setAuthToken } from './services/auth'

import { NotificationProvider, useNotification } from './contexts/NotificationContext'

/* Components */
import AlbumPage from './components/AlbumPage'
import ArtistPage from './components/ArtistPage'
import CollageForm from './components/CollageForm'
import CollagePage from './components/CollagePage'
import Friends from './components/Recs/Friends'
import HeroPage from './components/HeroPage'
import Library from './components/Library'
import Login from './components/Login'
import MixPage from './components/MixPage'
import NavigationBar from './components/NavigationBar'
import PlaylistPage from './components/PlaylistPage'
import Recs from './components/Recs'
import SignUp from './components/SignUp'
import SongPage from './components/SongPage'
import Unauthorized from './components/Unauthorized'
import UniversalAdd from './components/UniversalAdd'
import Profile from './components/Profile'
import Recent from './components/Recent'

import axios from 'axios'
import API_URL from './config'

import './styles/fonts.css'

function AppContent() {
  const [auth, setAuth] = useState(false)
  const [authChecked, setAuthChecked] = useState(false)
  const { setHasNewRecs } = useNotification()

  useEffect(() => {
    setAuth(isAuthenticated())
    setAuthChecked(true)
  }, [])

  const handleLogout = () => {
    logout()
    setAuth(false)
  }

  // Don't render routes until we've checked auth status
  if (!authChecked) {
    return null // or a loading spinner
  }

  return (
    <div className='App'>
      <NavigationBar auth={auth} handleLogout={handleLogout} />
      <div className='main-content'>
        <Routes>
          <Route
            path='/login'
            element={!auth ? <Login setAuth={setAuth} /> : <Navigate to='/library' />}
          />
          <Route path='/library' element={auth ? <Library /> : <Navigate to='/login' />} />
          <Route path='/recs' element={auth ? <Recs /> : <Navigate to='/login' />} />
          <Route path='/Library' element={auth ? <Library /> : <Navigate to='/login' />} />
          <Route path='/add' element={<UniversalAdd />} />
          <Route path='/' element={<HeroPage />} />
          <Route path='/song/:id' element={<SongPage />} />
          <Route path='/album/:id' element={<AlbumPage />} />
          <Route path='/playlist/:id' element={<PlaylistPage />} />
          <Route path='/mix/:id' element={<MixPage />} />
          <Route path='/artist/:id' element={<ArtistPage />} />
          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='/profile' element={<Profile />} />
          <Route
            path='/collage/create'
            element={<CollageForm onSubmit={(data) => console.log('Collage created:', data)} />}
          />
          <Route path='/collage/:id' element={<CollagePage />} />
          <Route path='/signup' element={!auth ? <SignUp /> : <Navigate to='/library' />} />
          <Route path='/profile' element={auth ? <Profile /> : <Navigate to='/login' />} />
          <Route path='/recent' element={<Recent />} />
        </Routes>
      </div>
    </div>
  )
}

function App() {
  return (
    <Router>
      <NotificationProvider>
        <AppContent />
      </NotificationProvider>
    </Router>
  )
}

export default App
