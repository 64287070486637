function MediaActionButton({
  onClick,
  isActive,
  isLoading,
  actionType, // 'Queue', 'Collection', 'Collage', or 'Remove'
  isPartial,
  isQueueSection = false,
}) {
  const getButtonClass = () => {
    if (actionType === 'Collection' && isActive) {
      return isPartial ? 'text-blue' : 'text-fuchsia'
    }
    if ((actionType === 'Queue' || actionType === 'Collage') && isActive) {
      return 'text-fuchsia'
    }
    return ''
  }

  const getIconSrc = () => {
    if (isLoading) return '/glyph/loading.svg'

    const iconMap = {
      Queue: '/glyph/queue.svg',
      Collection: '/glyph/collect.svg',
      Collage: '/glyph/collage.svg',
      Remove: '/glyph/remove.svg',
    }
    return iconMap[actionType]
  }

  // Use context-button for text-only buttons (like in QueueSection)
  // Use context-glyph for icon buttons
  const baseClass =
    isQueueSection && actionType !== 'Collection'
      ? 'context-button'
      : 'context-button context-glyph'

  if (isLoading) {
    return (
      <button className={`${baseClass} ${getButtonClass()}`} disabled>
        <img src='/glyph/loading.svg' alt='Loading' className='h-4 w-4 animate-spin' />
      </button>
    )
  }

  return (
    <button onClick={onClick} className={`${baseClass} ${getButtonClass()}`} disabled={isLoading}>
      {isQueueSection && actionType === 'Collection' ? (
        <div className='flex items-center gap-1'>
          <span className='direction-arrow'>→</span>
          <img src={getIconSrc()} alt='Move to Collection' className='h-4 w-4' />
        </div>
      ) : (
        <img
          src={getIconSrc()}
          alt={
            actionType === 'Remove'
              ? 'Remove'
              : `${isActive ? 'Remove from' : 'Add to'} ${actionType}`
          }
          className='h-4 w-4'
        />
      )}
    </button>
  )
}

export default MediaActionButton
