import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import {
  addToQueue,
  moveToCollection,
  removeFromQueue,
  checkMediaStatus,
} from '../services/mediaService'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'

function AlbumPage() {
  const [album, setAlbum] = useState(null)
  const { id } = useParams()
  const [trackLoadingStatus, setTrackLoadingStatus] = useState({})

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    fetchUserMedia,
    itemCollectionStatus,
  } = useMediaActions('album', id, album?.tracks || [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const albumResponse = await axios.get(`${API_URL}albums/${id}/`)
        setAlbum(albumResponse.data)

        const loadingStatus = {}
        albumResponse.data.tracks.forEach((track) => {
          loadingStatus[track.id] = false
        })
        setTrackLoadingStatus(loadingStatus)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [id])

  const handleitemCollectionAction = async (trackId, isRemoving) => {
    try {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: true }))
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/song/${trackId}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: 'song',
          id: trackId,
        })
      }
      await fetchUserMedia()
    } catch (error) {
      console.error(
        `Error ${isRemoving ? 'removing' : 'adding'} track to collection:`,
        error
      )
    } finally {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: false }))
    }
  }

  const renderListenLinks = (track) => {
    const links = []
    if (track.spotify_id)
      links.push(
        <a
          key="spotify"
          href={`https://open.spotify.com/track/${track.spotify_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify
        </a>
      )
    if (track.apple_id)
      links.push(
        <a
          key="apple"
          href={`https://music.apple.com/us/song/${track.apple_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Apple Music
        </a>
      )
    if (track.bandcamp_url)
      links.push(
        <a
          key="bandcamp"
          href={track.bandcamp_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Bandcamp
        </a>
      )
    if (track.youtube_id)
      links.push(
        <a
          key="youtube"
          href={`https://www.youtube.com/watch?v=${track.youtube_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube
        </a>
      )
    return links.length > 0
      ? links.reduce((prev, curr) => [prev, ' | ', curr])
      : 'No links available'
  }

  if (!album) return <div>Loading...</div>

  return (
    <div>
      <h1>{album.title}</h1>
      <p>
        Artists:{' '}
        {album.primary_artists
          .map((artist) => (
            <Link key={artist.id} to={`/artist/${artist.id}`}>
              {artist.name}
            </Link>
          ))
          .reduce((prev, curr) => [prev, ', ', curr])}
      </p>
      <p>Label: {album.label}</p>
      <MediaActionButton
        onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
        isActive={inQueue}
        isLoading={queueLoading}
        actionType="Queue"
      />
      <br />
      <MediaActionButton
        onClick={() => handleCollectionAction(inCollection)}
        isActive={inCollection}
        isLoading={collectionLoading}
        actionType="Collection"
      />
      <p>
        Listen on:{' '}
        {album.spotify_id && (
          <a
            href={`https://open.spotify.com/album/${album.spotify_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify
          </a>
        )}
        {album.spotify_id && album.apple_id && ' | '}
        {album.apple_id && (
          <a
            href={`https://music.apple.com/us/album/${album.apple_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Music
          </a>
        )}
        {(album.spotify_id || album.apple_id) && album.bandcamp_url && ' | '}
        {album.bandcamp_url && (
          <a
            href={album.bandcamp_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Bandcamp
          </a>
        )}
        {(album.spotify_id || album.apple_id || album.bandcamp_url) &&
          album.youtube_id &&
          ' | '}
        {album.youtube_id && (
          <a
            href={`https://www.youtube.com/playlist?list=${album.youtube_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
        )}
      </p>
      <h2>Tracks:</h2>
      <ol>
        {album.tracks.map((track) => (
          <li key={track.id}>
            <Link to={`/song/${track.id}`}>{track.title}</Link> -{' '}
            {renderListenLinks(track)}
            <MediaActionButton
              onClick={() =>
                itemCollectionStatus[track.id]
                  ? handleitemCollectionAction(track.id, true)
                  : handleitemCollectionAction(track.id, false)
              }
              isActive={itemCollectionStatus[track.id]}
              isLoading={trackLoadingStatus[track.id]}
              actionType="Collection"
            />
          </li>
        ))}
      </ol>
    </div>
  )
}

export default AlbumPage
