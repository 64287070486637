import { useState } from 'react'
import axios from 'axios'
import API_URL from '../config'
import ProcessLog from './ProcessLog'
import useProcessLog from '../hooks/useProcessLog'

function UrlSubmitForm({ setError, setSuccessMessage }) {
  const [url, setUrl] = useState('')
  const { showLog, setShowLog, logOutput, addLog, updateLastLog, resetLogs } =
    useProcessLog()

  const handleSubmit = async (queueOnly) => {
    setShowLog(true)
    resetLogs()
    addLog('Starting request')

    try {
      const token = localStorage.getItem('token')
      addLog('Fetching token', true)

      updateLastLog(false)
      addLog('Processing URL', true)

      const response = await axios.post(
        `${API_URL}process-url/`,
        { url: url, queue_only: queueOnly },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      updateLastLog(false)
      addLog(`URL processed: ${JSON.stringify(response.data.url)}`)

      setUrl('')
      setError(null)
      setSuccessMessage(response.data.message || 'URL successfully processed!')
    } catch (error) {
      updateLastLog(false)
      const errorMessage =
        error.response?.data?.error ||
        'An error occurred while processing the URL.'
      addLog(`Error processing URL: ${errorMessage}`)

      if (error.response?.data?.error === 'Playlist already exists') {
        setError('Playlist already exists')
        setSuccessMessage('')
      } else {
        setError(errorMessage)
        setSuccessMessage('')
      }
    } finally {
      addLog('Request finished')
    }
  }

  return (
    <div className="submit-container mt-5">
      <h4>Submit a URL</h4>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter a URL..."
      />
      <button className="context-button" onClick={() => handleSubmit(false)}>
        + Collection
      </button>
      <button className="context-button" onClick={() => handleSubmit(true)}>
        + Queue
      </button>
      {showLog && <ProcessLog logOutput={logOutput} setShowLog={setShowLog} />}
    </div>
  )
}

export default UrlSubmitForm
