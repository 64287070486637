import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import useMediaActions from '../hooks/useMediaActions'
import MediaActionButton from './common/MediaActionButton'
import { getServiceLinks } from './Library/utils/serviceLinks'

function CollagePage() {
  const [collage, setCollage] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [items, setItems] = useState([])
  const [removeLoadingStates, setRemoveLoadingStates] = useState({})
  const { id } = useParams()

  const {
    itemCollectionStatus,
    itemLoadingStatus,
    handleItemCollectionAction,
    handleAddToQueue,
    handleRemoveFromQueue,
    itemQueueStatus,
    itemQueueLoading,
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleCollectionAction,
  } = useMediaActions('collage', id, items, true)

  useEffect(() => {
    const fetchCollage = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}collages/${id}/`)
        setCollage(response.data)
        setItems(response.data.items)
      } catch (error) {
        console.error('Error fetching collage:', error)
      }
    }

    fetchCollage()
  }, [id])

  const handleRemoveFromCollage = async (type, itemId) => {
    try {
      await axios.post(`${API_URL}collages/${id}/remove/`, {
        media_type: type,
        media_id: itemId,
      })
      setItems((prevItems) => prevItems.filter((item) => item.id !== itemId))
    } catch (error) {
      console.error('Error removing item from collage:', error)
    }
  }

  const moveItem = async (index, direction) => {
    const newItems = [...items]
    const item = newItems[index]
    newItems.splice(index, 1)
    newItems.splice(index + direction, 0, item)
    setItems(newItems)
  }

  // const handleSaveCollage = async (formData) => {
  //   try {
  //     await axios.patch(`${API_URL}collages/${id}/update/`, {
  //       ...formData,
  //       items: items.map((item, index) => ({
  //         id: item.id,
  //         type: item.type,
  //         order: index,
  //       })),
  //     })
  //     setCollage((prev) => ({ ...prev, ...formData }))
  //     setIsEditing(false)
  //     const response = await axios.get(`${API_URL}collages/${id}/`)
  //     setCollage(response.data)
  //     setItems(response.data.items)
  //   } catch (error) {
  //     console.error('Error updating collage:', error)
  //   }
  // }

  const handleRemoveWithLoading = async (type, itemId) => {
    try {
      setRemoveLoadingStates((prev) => ({ ...prev, [itemId]: true }))
      await handleRemoveFromCollage(type, itemId)
    } finally {
      setRemoveLoadingStates((prev) => ({ ...prev, [itemId]: false }))
    }
  }

  if (!collage) return <div>Loading...</div>

  const getItemLink = (item) => {
    switch (item.type) {
      case 'song':
        return `/song/${item.id}`
      case 'album':
        return `/album/${item.id}`
      case 'playlist':
        return `/playlist/${item.id}`
      case 'mix':
        return `/mix/${item.id}`
      case 'collage':
        return `/collage/${item.id}`
      default:
        return '#'
    }
  }

  const renderItemDetails = (item) => (
    <div className='metadata'>
      <div className='info-line'>
        <Link to={getItemLink(item)} className='metadata-text primary interactive'>
          {item.title}
        </Link>
        {item.primary_artists && (
          <>
            <span className='metadata-text secondary'> by </span>
            {item.primary_artists
              .map((artist) => (
                <Link
                  key={artist.id}
                  to={`/artist/${artist.id}`}
                  className='metadata-text primary interactive'
                >
                  {artist.name}
                </Link>
              ))
              .reduce((prev, curr) => [prev, ', ', curr])}
          </>
        )}
      </div>
      {item.type !== 'collage' && (
        <div className='service-links'>
          <span className='metadata-text secondary media-type'>{item.type}</span>
          <span className='separator slash'>/</span>
          {getServiceLinks(item, item.type)}
        </div>
      )}
    </div>
  )

  return (
    <div className='media-list'>
      <div className='media-header'>
        <h1 className='metadata-text primary'>{collage.title}</h1>
        <div className='media-actions'>
          <MediaActionButton
            onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
            isActive={inQueue}
            isLoading={queueLoading}
            actionType='Queue'
          />
          <MediaActionButton
            onClick={() => handleCollectionAction(inCollection)}
            isActive={inCollection}
            isLoading={collectionLoading}
            actionType='Collection'
          />
          <button onClick={() => setIsEditing(true)} className='context-button'>
            Edit
          </button>
        </div>
      </div>

      <div className='metadata'>
        {collage.description && (
          <div className='info-line'>
            <span className='metadata-text secondary'>{collage.description}</span>
          </div>
        )}
        <div className='info-line'>
          <span className='metadata-text secondary'>Items: {items.length}</span>
        </div>
      </div>

      <div className='mt-5'>
        <ul className='left-justify'>
          {items.map((item, index) => (
            <li key={`${item.type}-${item.id}`}>
              {renderItemDetails(item)}
              <div className='media-actions'>
                {!isEditing ? (
                  <>
                    <MediaActionButton
                      onClick={() =>
                        itemQueueStatus[item.id]
                          ? handleRemoveFromQueue(item.type, item.id)
                          : handleAddToQueue(item.type, item.id)
                      }
                      isActive={itemQueueStatus[item.id]}
                      isLoading={itemQueueLoading[item.id]}
                      actionType='Queue'
                    />
                    <MediaActionButton
                      onClick={() =>
                        itemCollectionStatus[item.id]
                          ? handleItemCollectionAction(item, true)
                          : handleItemCollectionAction(item, false)
                      }
                      isActive={itemCollectionStatus[item.id]}
                      isLoading={itemLoadingStatus[item.id]}
                      actionType='Collection'
                    />
                    <MediaActionButton
                      onClick={() => handleRemoveWithLoading(item.type, item.id)}
                      isLoading={removeLoadingStates[item.id]}
                      actionType='Remove'
                    />
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => moveItem(index, -1)}
                      disabled={index === 0}
                      className='context-button'
                    >
                      ↑
                    </button>
                    <button
                      onClick={() => moveItem(index, 1)}
                      disabled={index === items.length - 1}
                      className='context-button'
                    >
                      ↓
                    </button>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CollagePage
