import { useState } from 'react'

const useProcessLog = () => {
  const [showLog, setShowLog] = useState(false)
  const [logOutput, setLogOutput] = useState([])

  const addLog = (message, showLoading = false) => {
    setLogOutput((prev) => [...prev, { message, loading: showLoading }])
  }

  const updateLastLog = (loading = false) => {
    setLogOutput((prev) => {
      const newLogs = [...prev]
      if (newLogs.length > 0) {
        newLogs[newLogs.length - 1].loading = loading
      }
      return newLogs
    })
  }

  const resetLogs = () => {
    setLogOutput([])
  }

  return {
    showLog,
    setShowLog,
    logOutput,
    addLog,
    updateLastLog,
    resetLogs,
  }
}

export default useProcessLog
