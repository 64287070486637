import { useState } from 'react'
import axios from 'axios'
import API_URL from '../../config'
import { setAuthToken } from '../../services/auth'
import { debounce } from 'lodash'

function SendRecModal({ onClose, onSuccess }) {
  const [friendSearchQuery, setFriendSearchQuery] = useState('')
  const [friendSearchResults, setFriendSearchResults] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [newRec, setNewRec] = useState({
    username: '',
    media_type: '',
    media_id: '',
    message: '',
  })

  const debouncedFriendSearch = debounce(async (query) => {
    if (query.length < 2) {
      setFriendSearchResults([])
      return
    }
    try {
      const response = await axios.get(`${API_URL}search-friends/?query=${query}`)
      setFriendSearchResults(response.data)
    } catch (error) {
      console.error('Error searching friends:', error)
    }
  }, 300)

  const debouncedSearch = debounce(async (query) => {
    if (query.length < 2) {
      setSearchResults([])
      return
    }
    try {
      const response = await axios.get(`${API_URL}search-media/?query=${query}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error searching media:', error)
    }
  }, 300)

  const sendRec = async (e) => {
    e.preventDefault()
    if (!newRec.username || !newRec.media_type || !newRec.media_id) return

    try {
      setAuthToken(localStorage.getItem('token'))
      await axios.post(`${API_URL}send-rec/`, newRec)
      alert('Rec sent successfully!')
      onSuccess()
      onClose()
    } catch (error) {
      console.error('Error sending rec:', error)
      alert('Failed to send rec')
    }
  }

  return (
    <div
      className='fixed inset-0 flex items-center justify-center'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div style={{ backgroundColor: 'white' }} className='w-96 rounded-lg p-6'>
        <div className='mb-4 flex items-center justify-between'>
          <h3 className='text-lg font-bold'>Send Recommendation</h3>
          <button onClick={onClose} className='text-gray-500 hover:text-gray-700'>
            ×
          </button>
        </div>
        <form onSubmit={sendRec}>
          <input
            type='text'
            value={friendSearchQuery}
            onChange={(e) => {
              setFriendSearchQuery(e.target.value)
              debouncedFriendSearch(e.target.value)
            }}
            placeholder='Search for a friend...'
            className='mb-4 w-full rounded border px-2 py-1 text-sm'
          />
          {friendSearchResults.length > 0 && (
            <ul className='mb-4 max-h-48 overflow-y-auto'>
              {friendSearchResults.map((friend) => (
                <li
                  key={friend.id}
                  onClick={() => {
                    setNewRec({
                      ...newRec,
                      username: friend.username,
                    })
                    setFriendSearchQuery(friend.username)
                    setFriendSearchResults([])
                  }}
                  className='hover:bg-gray-100 cursor-pointer p-2'
                >
                  {friend.username}
                </li>
              ))}
            </ul>
          )}
          <input
            type='text'
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value)
              debouncedSearch(e.target.value)
            }}
            placeholder='Search for song, album, playlist, or artist...'
            className='mb-4 w-full rounded border px-2 py-1 text-sm'
          />
          {searchResults.length > 0 && (
            <ul className='mb-4 max-h-48 overflow-y-auto'>
              {searchResults.map((result) => (
                <li
                  key={result.id}
                  onClick={() => {
                    setNewRec({
                      ...newRec,
                      media_type: result.type,
                      media_id: result.id,
                    })
                    setSearchQuery(result.title)
                    setSearchResults([])
                  }}
                  className='hover:bg-gray-100 cursor-pointer p-2'
                >
                  {result.title} ({result.type})
                </li>
              ))}
            </ul>
          )}
          <textarea
            value={newRec.message}
            onChange={(e) => setNewRec({ ...newRec, message: e.target.value })}
            placeholder='Message'
            className='mb-4 w-full rounded border px-2 py-1 text-sm'
          />
          <button type='submit' className='context-button w-full py-1 text-sm'>
            Send Recommendation
          </button>
        </form>
      </div>
    </div>
  )
}

export default SendRecModal
