import { useState, useEffect } from 'react'
import { handleSelect, handleAddToQueue } from '../utils/mediaHandlers'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'

function SearchResults({ results, currentService, playing, playPreview }) {
  const [userCollection, setUserCollection] = useState([])
  const [userQueue, setUserQueue] = useState([])
  const [loadingItems, setLoadingItems] = useState({})

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const [collectionResponse, queueResponse] = await Promise.all([
          axios.get(`${API_URL}user-media/`),
          axios.get(`${API_URL}user-queue/`),
        ])
        setUserCollection(collectionResponse.data)
        setUserQueue(queueResponse.data)
        console.log('Fetched user collection:', collectionResponse.data)
        console.log('Fetched user queue:', queueResponse.data)
      } catch (error) {
        console.error('Error fetching user data:', error)
      }
    }

    fetchUserData()
  }, [])

  const isInCollection = (item) => {
    return userCollection.some((collectionItem) => {
      if (item.type !== collectionItem.media_type) return false

      // Check by service-specific IDs
      return (
        collectionItem.spotify_id === item.id ||
        collectionItem.apple_id === item.id ||
        (item.type === 'playlist' && collectionItem.src_url?.includes(item.id))
      )
    })
  }

  const isInQueue = (item) => {
    return userQueue.some((queueItem) => {
      if (item.type !== queueItem.media_type) return false

      // Check by service-specific IDs
      return (
        queueItem.spotify_id === item.id ||
        queueItem.apple_id === item.id ||
        (item.type === 'playlist' && queueItem.src_url?.includes(item.id))
      )
    })
  }

  const handleCollectionClick = async (item) => {
    console.log(`Handling collection click for item:`, item)
    setLoadingItems((prev) => ({ ...prev, [item.id]: true }))
    try {
      if (isInCollection(item)) {
        const collectionItem = userCollection.find((media) => {
          if (item.type !== media.media_type) return false
          return (
            media.spotify_id === item.id ||
            media.apple_id === item.id ||
            (item.type === 'playlist' && media.src_url?.includes(item.id))
          )
        })
        if (collectionItem) {
          await axios.delete(`${API_URL}user-media/${item.type}/${collectionItem.id}/`)
        }
      } else {
        await handleSelect(item, currentService)
      }
      const collectionResponse = await axios.get(`${API_URL}user-media/`)
      setUserCollection(collectionResponse.data)
    } catch (error) {
      console.error('Error handling collection click:', error)
    } finally {
      setLoadingItems((prev) => ({ ...prev, [item.id]: false }))
    }
  }

  const handleQueueClick = async (item) => {
    setLoadingItems((prev) => ({ ...prev, [item.id]: true }))
    try {
      if (isInQueue(item)) {
        const queueItem = userQueue.find(
          (queueItem) =>
            queueItem.media_type === item.type &&
            (queueItem.spotify_id === item.id || queueItem.apple_id === item.id)
        )
        if (queueItem) {
          await axios.delete(`${API_URL}queue/remove/${queueItem.queue_id}/`)
        }
      } else {
        await handleAddToQueue(item, currentService)
      }
      const queueResponse = await axios.get(`${API_URL}user-queue/`)
      setUserQueue(queueResponse.data)
    } catch (error) {
      console.error('Error handling queue click:', error)
    } finally {
      setLoadingItems((prev) => ({ ...prev, [item.id]: false }))
    }
  }

  const isPartiallyCollected = (item) => {
    if (item.type === 'album') {
      const collectionItem = userCollection.find(
        (media) =>
          media.media_type === item.type &&
          (media.spotify_id === item.id || media.apple_id === item.id)
      )
      return collectionItem && !collectionItem.direct_add
    }
    return false // Songs are never partial
  }

  return (
    <div className='media-list'>
      {results.tracks && (
        <div>
          <h3>Tracks</h3>
          <ul>
            {results.tracks.map((item) => (
              <li key={item.id}>
                <div className='media-thumbnail'>
                  {item.album?.images.length > 0 && (
                    <img
                      src={item.album.images[item.album.images.length - 1].url}
                      alt={`${item.album.name} cover`}
                    />
                  )}
                  {item.preview_url && (
                    <button
                      onClick={() => playPreview(item.preview_url, item.id)}
                      className='context-button play-button'
                    >
                      {playing === item.id ? '⏸' : '▶'}
                    </button>
                  )}
                </div>

                <div className='media-info'>
                  <div className='info-line'>
                    <span className='metadata-text primary'>{item.name}</span>
                    <span className='metadata-text secondary'>by</span>
                    <span className='metadata-text primary'>
                      {item.artists.map((artist) => artist.name).join(', ')}
                    </span>
                  </div>
                  {currentService && (
                    <div className='metadata-text secondary'>from {currentService}</div>
                  )}
                </div>

                <div className='media-actions'>
                  <MediaActionButton
                    onClick={() => handleCollectionClick({ ...item, type: 'song' })}
                    isActive={isInCollection({ ...item, type: 'song' })}
                    isLoading={loadingItems[item.id]}
                    actionType='Collection'
                  />
                  <MediaActionButton
                    onClick={() => handleQueueClick({ ...item, type: 'song' })}
                    isActive={isInQueue({ ...item, type: 'song' })}
                    isLoading={loadingItems[item.id]}
                    actionType='Queue'
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {results.albums && (
        <div>
          <h3>Albums</h3>
          <ul>
            {results.albums.map((item) => (
              <li key={item.id}>
                <div className='media-thumbnail'>
                  {item.images.length > 0 && (
                    <img src={item.images[item.images.length - 1].url} alt={`${item.name} cover`} />
                  )}
                </div>

                <div className='media-info'>
                  <div className='info-line'>
                    <span className='metadata-text primary'>{item.name}</span>
                    <span className='metadata-text secondary'>by</span>
                    <span className='metadata-text primary'>
                      {item.artists.map((artist) => artist.name).join(', ')}
                    </span>
                  </div>
                  {currentService && (
                    <div className='metadata-text secondary'>from {currentService}</div>
                  )}
                </div>

                <div className='media-actions'>
                  <MediaActionButton
                    onClick={() => handleCollectionClick({ ...item, type: 'album' })}
                    isActive={isInCollection({ ...item, type: 'album' })}
                    isLoading={loadingItems[item.id]}
                    actionType='Collection'
                    isPartial={isPartiallyCollected({ ...item, type: 'album' })}
                  />
                  <MediaActionButton
                    onClick={() => handleQueueClick({ ...item, type: 'album' })}
                    isActive={isInQueue({ ...item, type: 'album' })}
                    isLoading={loadingItems[item.id]}
                    actionType='Queue'
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {results.playlists && (
        <div>
          <h3>Playlists</h3>
          <ul>
            {results.playlists.map((item) => (
              <li key={item.id}>
                <div className='media-thumbnail'>
                  {item.images.length > 0 && (
                    <img src={item.images[item.images.length - 1].url} alt={`${item.name} cover`} />
                  )}
                </div>

                <div className='media-info'>
                  <div className='info-line'>
                    <span className='metadata-text primary'>{item.name}</span>
                    <span className='metadata-text secondary'>by</span>
                    <span className='metadata-text primary'>{item.owner.display_name}</span>
                  </div>
                  {currentService && (
                    <div className='metadata-text secondary'>from {currentService}</div>
                  )}
                </div>

                <div className='media-actions'>
                  <MediaActionButton
                    onClick={() => handleQueueClick({ ...item, type: 'playlist' })}
                    isActive={isInQueue({ ...item, type: 'playlist' })}
                    isLoading={loadingItems[item.id]}
                    actionType='Queue'
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default SearchResults
