import { useState } from 'react'

export const useLibrarySearch = (userMedia) => {
  const [showSearch, setShowSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const getSearchResults = () => {
    if (!searchQuery.trim()) return null

    const query = searchQuery.toLowerCase().trim()

    const filterItems = (items = [], type) => {
      const filtered = items.filter(
        (item) =>
          (item.title?.toLowerCase().includes(query) ||
            item.primary_artists?.some((artist) => artist.name.toLowerCase().includes(query)) ||
            item.description?.toLowerCase().includes(query)) &&
          (type !== 'albums' || item.direct_add)
      )
      return filtered
    }

    const results = {
      queue: filterItems(userMedia?.queue || [], 'queue'),
      songs: filterItems(userMedia?.collection?.songs || [], 'songs'),
      albums: filterItems(userMedia?.collection?.albums || [], 'albums'),
      playlists: filterItems(userMedia?.collection?.playlists || [], 'playlists'),
      mixes: filterItems(userMedia?.collection?.mixes || [], 'mixes'),
      collages: filterItems(userMedia?.collection?.collages || [], 'collages'),
    }

    const filteredResults = Object.fromEntries(
      Object.entries(results).filter(([_, items]) => items.length > 0)
    )

    return Object.keys(filteredResults).length > 0 ? filteredResults : {}
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  return {
    showSearch,
    setShowSearch,
    searchQuery,
    setSearchQuery,
    handleSearch,
    getSearchResults,
  }
}
