// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/Scto Grotesk A Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/fonts/Scto Grotesk A Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/fonts/Scto Grotesk A Regular Italic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../public/fonts/Scto Grotesk A Bold Italic.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'SctoGrotesk';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SctoGrotesk';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SctoGrotesk';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'SctoGrotesk';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,+DAA4E;IAC5E,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;IAC1B,+DAAyE;IACzE,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;IAC1B,+DAAmF;IACnF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;IAC1B,+DAAgF;IAChF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":["@font-face {\n    font-family: 'SctoGrotesk';\n    src: url('../../public/fonts/Scto Grotesk A Regular.otf') format('opentype');\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n  }\n  \n  @font-face {\n    font-family: 'SctoGrotesk';\n    src: url('../../public/fonts/Scto Grotesk A Bold.otf') format('opentype');\n    font-weight: 700;\n    font-style: normal;\n    font-display: swap;\n  }\n  \n  @font-face {\n    font-family: 'SctoGrotesk';\n    src: url('../../public/fonts/Scto Grotesk A Regular Italic.otf') format('opentype');\n    font-weight: 400;\n    font-style: italic;\n    font-display: swap;\n  }\n  \n  @font-face {\n    font-family: 'SctoGrotesk';\n    src: url('../../public/fonts/Scto Grotesk A Bold Italic.otf') format('opentype');\n    font-weight: 700;\n    font-style: italic;\n    font-display: swap;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
