import { useState } from 'react'
import MediaList from './MediaList'

function CollectionSection({
  userMedia,
  currentCollectionSection,
  handleRemoveFromCollection,
  handleAddToCollage,
}) {
  const [removeLoadingStates, setRemoveLoadingStates] = useState({})

  const getMediaType = () => {
    switch (currentCollectionSection) {
      case 'songs':
        return 'song'
      case 'albums':
        return 'album'
      case 'playlists':
        return 'playlist'
      case 'mixes':
        return 'mix'
      case 'collages':
        return 'collage'
      default:
        return currentCollectionSection
    }
  }

  const handleRemoveWithLoading = async (type, id) => {
    try {
      setRemoveLoadingStates((prev) => ({ ...prev, [id]: true }))
      await handleRemoveFromCollection(type, id)
    } finally {
      setRemoveLoadingStates((prev) => ({ ...prev, [id]: false }))
    }
  }

  return (
    <section id='collection'>
      <div className='media-section'>
        <MediaList
          mediaItems={userMedia.collection[currentCollectionSection]}
          type={getMediaType()}
          onRemoveFromCollection={handleRemoveWithLoading}
          onAddToCollage={handleAddToCollage}
          removeLoadingStates={removeLoadingStates}
        />
      </div>
    </section>
  )
}

export default CollectionSection
