import { XCircleIcon } from '@heroicons/react/24/solid'
import LoadingEllipsis from './common/LoadingEllipsis'

const ProcessLog = ({ logOutput, setShowLog }) => {
  return (
    <div className="relative z-0 grid w-auto place-content-center overflow-hidden border-2 border-solid border-contextGreen bg-contextBlack">
      <button
        className="mono pointer absolute right-0 top-0 z-30 cursor-pointer p-1 text-contextWhite"
        onClick={() => setShowLog(false)}
      >
        <XCircleIcon className="size-6 text-contextGreen hover:text-contextWhite" />
      </button>
      <div className="mono absolute left-0 top-0 z-20 max-h-[300px] w-auto overflow-y-auto p-5 text-left text-contextGreen">
        {logOutput.map((log, index) => (
          <div
            key={index}
            ref={
              index === logOutput.length - 1
                ? (el) => el?.scrollIntoView()
                : null
            }
          >
            {log.message}
            {log.loading && <LoadingEllipsis loading={true} />}
          </div>
        ))}
      </div>
      <img className="z-10 animate-pulse" src="/logo192.png" alt="Context" />
    </div>
  )
}

export default ProcessLog
