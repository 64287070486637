import { useState, useEffect } from 'react'
import axios from 'axios'
import API_URL from '../../config'
import { setAuthToken } from '../../services/auth'
import AddFriendModal from './AddFriendModal'
import SendRecModal from './SendRecModal'

export function FriendsSection() {
  const [friends, setFriends] = useState([])
  const [friendRequests, setFriendRequests] = useState([])
  const [showAddFriendModal, setShowAddFriendModal] = useState(false)
  const [showSendRecModal, setShowSendRecModal] = useState(false)

  useEffect(() => {
    fetchFriends()
    fetchFriendRequests()
  }, [])

  const fetchFriends = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      const response = await axios.get(`${API_URL}get-friends/`)
      setFriends(response.data)
    } catch (error) {
      console.error('Error fetching friends:', error)
    }
  }

  const fetchFriendRequests = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      const response = await axios.get(`${API_URL}get-friend-requests/`)
      setFriendRequests(response.data)
    } catch (error) {
      console.error('Error fetching friend requests:', error)
    }
  }

  const acceptFriendRequest = async (username) => {
    try {
      setAuthToken(localStorage.getItem('token'))
      await axios.post(`${API_URL}accept-friend-request/`, { username })
      alert('Friend request accepted!')
      fetchFriends()
      fetchFriendRequests()
    } catch (error) {
      console.error('Error accepting friend request:', error)
      alert('Failed to accept friend request')
    }
  }

  return (
    <div className='friends-list text-left'>
      <ul className='mb-4 w-full'>
        {friends.map((friend) => (
          <li key={friend.id} className='mb-2'>
            <span className='text-base font-medium'>{friend.username}</span>
          </li>
        ))}
      </ul>

      {friendRequests.length > 0 && (
        <>
          <h4 className='mb-2 text-sm font-bold'>Friend Requests</h4>
          <ul className='mb-4 w-full'>
            {friendRequests.map((request) => (
              <li key={request.id} className='mb-2 flex items-center gap-2'>
                <span className='text-sm'>{request.username}</span>
                <button
                  onClick={() => acceptFriendRequest(request.username)}
                  className='context-button px-2 py-1 text-xs'
                >
                  Accept
                </button>
              </li>
            ))}
          </ul>
        </>
      )}

      <button
        className='context-button w-full py-1 text-sm'
        onClick={() => setShowAddFriendModal(true)}
      >
        Add Friend
      </button>

      <button
        className='context-button mt-2 w-full py-1 text-sm'
        onClick={() => setShowSendRecModal(true)}
      >
        Send Rec
      </button>

      {showAddFriendModal && (
        <AddFriendModal
          onClose={() => setShowAddFriendModal(false)}
          onSuccess={() => {
            fetchFriends()
            fetchFriendRequests()
          }}
        />
      )}

      {showSendRecModal && (
        <SendRecModal
          onClose={() => setShowSendRecModal(false)}
          onSuccess={() => {
            // You might want to add a success callback here
          }}
        />
      )}
    </div>
  )
}

export default FriendsSection
