import axios from 'axios'
import { setAuthToken } from './auth'
import API_URL from '../config'

export const addToQueue = async (mediaType, id) => {
  setAuthToken(localStorage.getItem('token'))
  return await axios.post(`${API_URL}queue/add/`, {
    media_type: mediaType,
    id: id,
  })
}

export const moveToCollection = async (queueItemId) => {
  setAuthToken(localStorage.getItem('token'))
  return await axios.post(`${API_URL}queue/move-to-collection/${queueItemId}/`)
}

export const removeFromQueue = async (queueItemId) => {
  setAuthToken(localStorage.getItem('token'))
  return await axios.delete(`${API_URL}queue/remove/${queueItemId}/`)
}

export const checkMediaStatus = async (mediaType, id) => {
  try {
    setAuthToken(localStorage.getItem('token'))
    const response = await axios.get(
      `${API_URL}check-media-status/${mediaType}/${id}/`
    )
    return response.data
  } catch (error) {
    console.error('Error checking media status:', error)
    throw error
  }
}

export const checkTrackStatus = async (id) => {
  return checkMediaStatus('song', id)
}
