import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'
import CollageSelectionModal from './CollageSelectionModal'
import { useUserMedia } from './Library/hooks/useUserMedia'

function MixPage() {
  const [mix, setMix] = useState(null)
  const { id } = useParams()
  const [showCollageModal, setShowCollageModal] = useState(false)
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    fetchUserMedia,
  } = useMediaActions('mix', id)

  const { userMedia } = useUserMedia()

  useEffect(() => {
    const fetchMix = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}mixes/${id}/`)
        setMix(response.data)
      } catch (error) {
        console.error('Error fetching mix:', error)
      }
    }

    fetchMix()
  }, [id])

  const handleAddToCollage = (event) => {
    const button = event.currentTarget
    const rect = button.getBoundingClientRect()

    if (showCollageModal) {
      setShowCollageModal(false)
    } else {
      setModalPosition({
        top: rect.bottom,
        left: rect.left,
      })
      setShowCollageModal(true)
    }
  }

  if (!mix) return <div>Loading...</div>

  return (
    <div className='media-list'>
      <div className='media-header'>
        <h1 className='metadata-text primary'>{mix.title}</h1>
        <div className='media-actions'>
          <MediaActionButton
            onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
            isActive={inQueue}
            isLoading={queueLoading}
            actionType='Queue'
          />
          <MediaActionButton
            onClick={() => handleCollectionAction(inCollection)}
            isActive={inCollection}
            isLoading={collectionLoading}
            actionType='Collection'
          />
          <MediaActionButton
            onClick={handleAddToCollage}
            isActive={false}
            isLoading={false}
            actionType='Collage'
          />
        </div>
      </div>

      {mix.src_url && (
        <div className='metadata'>
          <div className='service-links center'>
            <span className='metadata-text secondary'>Listen on:</span>
            <a
              href={mix.src_url}
              target='_blank'
              rel='noopener noreferrer'
              className='service-link'
            >
              {new URL(mix.src_url).hostname.replace('www.', '')}
            </a>
          </div>
        </div>
      )}

      {showCollageModal && (
        <CollageSelectionModal
          position={modalPosition}
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={{ type: 'mix', id: parseInt(id) }}
          fetchUserMedia={fetchUserMedia}
          collages={userMedia?.collection?.collages || []}
        />
      )}
    </div>
  )
}

export default MixPage
