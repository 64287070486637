import { useState, useEffect } from 'react'

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation,
} from 'react-router-dom'

import {
  isAuthenticated,
  logout,
  setupTokenRefresh,
  setAuthToken,
} from './services/auth'

import {
  NotificationProvider,
  useNotification,
} from './contexts/NotificationContext'

/* Components */
import AlbumPage from './components/AlbumPage'
import ArtistPage from './components/ArtistPage'
import CollageForm from './components/CollageForm'
import CollagePage from './components/CollagePage'
import Friends from './components/Friends'
import HeroPage from './components/HeroPage'
import Library from './components/Library'
import Login from './components/Login'
import MixPage from './components/MixPage'
import PlaylistPage from './components/PlaylistPage'
import Recs from './components/Recs'
import SignUp from './components/SignUp'
import SongPage from './components/SongPage'
import Unauthorized from './components/Unauthorized'
import UniversalAdd from './components/UniversalAdd'
import Profile from './components/Profile'
import Recent from './components/Recent'

import axios from 'axios'
import API_URL from './config'

import './App.css'

function NavigationBar({ auth, handleLogout }) {
  const { hasNewRecs } = useNotification()
  const location = useLocation()
  const isActive = (path) => location.pathname === path

  return (
    <nav className="bg-contextWhite px-5 py-5">
      {auth ? (
        <ul className="container grid grid-cols-5 place-items-center">
          <Link
            to="/"
            className="absolute -left-4 top-6 -rotate-90 cursor-pointer font-mono text-xs hover:text-contextGreen hover:underline"
          >
            context
          </Link>
          <li>
            <Link
              to="/library"
              className={isActive('/library') ? 'underline' : 'hover:underline'}
            >
              library
            </Link>
          </li>
          <li>
            <Link
              to="/add"
              className={isActive('/add') ? 'underline' : 'hover:underline'}
            >
              add
            </Link>
          </li>
          <li>
            <Link
              to="/friends"
              className={isActive('/friends') ? 'underline' : 'hover:underline'}
            >
              friends
            </Link>
          </li>
          <li>
            <Link
              to="/recs"
              className={isActive('/recs') ? 'underline' : 'hover:underline'}
            >
              recs
              {hasNewRecs && (
                <span className="notification-dot" key={Date.now()}></span>
              )}
            </Link>
          </li>
          <li>
            <Link
              to="/profile"
              className={isActive('/profile') ? 'underline' : 'hover:underline'}
            >
              profile
            </Link>
          </li>
          <li
            onClick={handleLogout}
            className="absolute -right-3 top-6 rotate-90 cursor-pointer font-mono text-xs hover:text-contextRed hover:underline"
          >
            logout
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <Link to="/login" className={isActive('/login') ? 'underline' : ''}>
              login
            </Link>
          </li>
        </ul>
      )}
    </nav>
  )
}

function AppContent() {
  const [auth, setAuth] = useState(false)
  const { setHasNewRecs } = useNotification()

  useEffect(() => {
    setAuth(isAuthenticated())
  }, [])

  const handleLogout = () => {
    logout()
    setAuth(false)
  }

  useEffect(() => {
    if (auth) {
      setupTokenRefresh()
      // Set up periodic recs fetching
      fetchRecs()
      const fetchRecsInterval = setInterval(fetchRecs, 60000 * 60 * 2) // Fetch every 2 hours
      return () => clearInterval(fetchRecsInterval)
    }
  }, [auth])

  const fetchRecs = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      const response = await axios.get(`${API_URL}get-recs/`)
      const newRecs = response.data

      // Check for new recs and update local storage
      const storedRecs = JSON.parse(localStorage.getItem('recs') || '[]')
      const hasNewRecs =
        newRecs.length > storedRecs.length ||
        newRecs.some(
          (rec) => !storedRecs.find((storedRec) => storedRec.id === rec.id)
        )

      if (hasNewRecs) {
        setHasNewRecs(true)
        localStorage.setItem('hasNewRecs', 'true')
      }

      localStorage.setItem('recs', JSON.stringify(newRecs))
    } catch (error) {
      console.error('Error fetching recs:', error)
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js'
    script.async = true
    document.body.appendChild(script)

    // Check for new recs on page load
    const hasNewRecs = localStorage.getItem('hasNewRecs') === 'true'
    setHasNewRecs(hasNewRecs)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="App">
      <NavigationBar auth={auth} handleLogout={handleLogout} />
      <Routes>
        <Route
          path="/login"
          element={
            !auth ? <Login setAuth={setAuth} /> : <Navigate to="/library" />
          }
        />
        <Route
          path="/Library"
          element={auth ? <Library /> : <Navigate to="/login" />}
        />
        <Route path="/add" element={<UniversalAdd />} />
        <Route path="/" element={<HeroPage />} />
        <Route path="/song/:id" element={<SongPage />} />
        <Route path="/album/:id" element={<AlbumPage />} />
        <Route path="/playlist/:id" element={<PlaylistPage />} />
        <Route path="/mix/:id" element={<MixPage />} />
        <Route path="/artist/:id" element={<ArtistPage />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/collage/create"
          element={
            <CollageForm
              onSubmit={(data) => console.log('Collage created:', data)}
            />
          }
        />
        <Route path="/collage/:id" element={<CollagePage />} />
        <Route
          path="/friends"
          element={auth ? <Friends /> : <Navigate to="/login" />}
        />
        <Route
          path="/recs"
          element={auth ? <Recs /> : <Navigate to="/login" />}
        />
        <Route
          path="/signup"
          element={!auth ? <SignUp /> : <Navigate to="/library" />}
        />
        <Route
          path="/profile"
          element={auth ? <Profile /> : <Navigate to="/login" />}
        />
        <Route path="/recent" element={<Recent />} />
      </Routes>
    </div>
  )
}

function App() {
  return (
    <Router>
      <NotificationProvider>
        <AppContent />
      </NotificationProvider>
    </Router>
  )
}

export default App
