import { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import API_URL from '../../config'
import { setAuthToken } from '../../services/auth'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash'
import { useNotification } from '../../contexts/NotificationContext'
import { FriendsSection } from './Friends'
import MediaActionButton from '../common/MediaActionButton'
import useMediaActions from '../../hooks/useMediaActions'

function Recs() {
  const [activeTab, setActiveTab] = useState('friends')
  const [incomingRecs, setIncomingRecs] = useState([])
  const [outgoingRecs, setOutgoingRecs] = useState([])
  const [newRec, setNewRec] = useState({
    username: '',
    media_type: '',
    media_id: '',
    message: '',
  })
  const { setHasNewRecs } = useNotification()
  const [recView, setRecView] = useState('incoming')
  const [searchResults, setSearchResults] = useState([])
  const [friendSearchResults, setFriendSearchResults] = useState([])
  const [showAddFriendModal, setShowAddFriendModal] = useState(false)
  const [showSendRecModal, setShowSendRecModal] = useState(false)

  const displayRecs = recView === 'incoming' ? incomingRecs : outgoingRecs

  // Map the recommendations to the format expected by useMediaActions
  const mappedItems = useMemo(
    () =>
      displayRecs.map((rec) => ({
        id: rec.media_id,
        type: rec.media_type,
        title: rec.media_title,
        primary_artists: rec.primary_artists,
        spotify_id: rec.spotify_id,
        youtube_id: rec.youtube_id,
        bandcamp_url: rec.bandcamp_url,
        apple_id: rec.apple_music_id,
      })),
    [displayRecs]
  )

  const {
    itemCollectionStatus,
    itemLoadingStatus,
    handleItemCollectionAction,
    handleAddToQueue,
    handleRemoveFromQueue,
    itemQueueStatus,
    itemQueueLoading,
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleCollectionAction,
  } = useMediaActions('recommendations', null, mappedItems, true)

  useEffect(() => {
    fetchRecs()
    // Clear the new recs notification after a 3-second delay
    const timer = setTimeout(() => {
      setHasNewRecs(false)
      localStorage.removeItem('hasNewRecs')
    }, 3000)

    // Clean up the timer
    return () => clearTimeout(timer)
  }, [setHasNewRecs])

  const fetchRecs = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      // Fetch both incoming and outgoing recs
      const [incomingResponse, outgoingResponse] = await Promise.all([
        axios.get(`${API_URL}get-recs/`),
        axios.get(`${API_URL}get-sent-recs/`),
      ])

      setIncomingRecs(incomingResponse.data)
      setOutgoingRecs(outgoingResponse.data)

      // Store incoming recs in localStorage for notifications
      localStorage.setItem('recs', JSON.stringify(incomingResponse.data))
    } catch (error) {
      console.error('Error fetching recs:', error)
    }
  }

  useEffect(() => {
    // Load stored recs on component mount
    const storedRecs = JSON.parse(localStorage.getItem('recs') || '[]')
    setIncomingRecs(storedRecs)

    // Fetch new recs
    fetchRecs()
  }, [])

  const sendRec = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      await axios.post(`${API_URL}send-rec/`, newRec)
      alert('Rec sent successfully!')
      setNewRec({ username: '', media_type: '', media_id: '', message: '' })
      fetchRecs()
    } catch (error) {
      console.error('Error sending rec:', error)
      alert('Failed to send rec')
    }
  }

  const getSpotifyLink = (type, id) => {
    return `https://open.spotify.com/${type}/${id}`
  }

  const getYouTubeLink = (id, type) => {
    if (type === 'album') {
      return `https://www.youtube.com/playlist?list=${id}`
    }
    return `https://www.youtube.com/watch?v=${id}`
  }

  const getBandcampLink = (url) => {
    return url
  }

  const getAppleMusicLink = (type, id) => {
    if (type === 'song') {
      return `https://music.apple.com/us/song/${id}`
    } else if (type === 'album') {
      return `https://music.apple.com/us/album/${id}`
    }
    return '#'
  }

  const debouncedSearch = debounce(async (query) => {
    if (query.length < 2) {
      setSearchResults([])
      return
    }
    try {
      const response = await axios.get(`${API_URL}search-media/?query=${query}`)
      setSearchResults(response.data)
    } catch (error) {
      console.error('Error searching media:', error)
    }
  }, 300) // 300ms delay

  const debouncedFriendSearch = debounce(async (query) => {
    if (query.length < 2) {
      setFriendSearchResults([])
      return
    }
    try {
      const response = await axios.get(`${API_URL}search-friends/?query=${query}`)
      setFriendSearchResults(response.data)
    } catch (error) {
      console.error('Error searching friends:', error)
    }
  }, 300) // 300ms delay

  const renderTabContent = () => {
    const displayRecs = recView === 'incoming' ? incomingRecs : outgoingRecs

    switch (activeTab) {
      case 'friends':
        return (
          <>
            {/* Mobile Friends Section */}
            <div className='friend-section md:hidden'>
              <FriendsSection />
            </div>

            <div className='md:grid md:grid-cols-6 md:gap-4'>
              {/* Desktop Friends Section */}
              <div className='col-span-1 hidden border-r border-r-[#f0f0f0] pr-4 md:block'>
                <FriendsSection />
              </div>

              {/* Rec Content - shared between mobile & desktop */}
              <div className='col-span-5 md:pl-4'>{renderRecContent(displayRecs)}</div>
            </div>
          </>
        )
      case 'syncs':
        return <div className='text-sm'>Collage sync functionality coming soon...</div>
      default:
        return null
    }
  }

  // Separated rec content rendering for reuse
  const renderRecContent = (displayRecs) => (
    <>
      <div className='rec-view-tabs'>
        <button
          onClick={() => setRecView('incoming')}
          className={`${
            recView === 'incoming'
              ? 'font-semibold text-contextBlack underline'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          incoming
        </button>
        /
        <button
          onClick={() => setRecView('outgoing')}
          className={`${
            recView === 'outgoing'
              ? 'font-semibold text-contextBlack underline'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          outgoing
        </button>
      </div>

      <div className='library-container'>
        <div className='centered-list'>
          <ul className='left-justify'>
            {displayRecs.map((rec) => (
              <li key={rec.id}>
                <div className='metadata'>
                  <div className='info-line'>
                    <Link
                      to={`/${rec.media_type}/${rec.media_id}`}
                      className='metadata-text primary interactive'
                    >
                      {rec.media_title}
                    </Link>
                    {rec.media_type !== 'playlist' &&
                      rec.media_type !== 'mix' &&
                      rec.primary_artists && (
                        <>
                          <span className='metadata-text secondary'> by </span>
                          {rec.primary_artists
                            .map((artist) => (
                              <Link
                                key={artist.id}
                                to={`/artist/${artist.id}`}
                                className='metadata-text primary interactive'
                              >
                                {artist.name}
                              </Link>
                            ))
                            .reduce((prev, curr) => [prev, ', ', curr])}
                        </>
                      )}
                  </div>
                  <div className='service-links'>
                    <span className='metadata-text secondary media-type'>{rec.media_type}</span>
                    <span className='separator slash'>/</span>
                    {rec.spotify_id && (
                      <a
                        href={getSpotifyLink(
                          rec.media_type === 'song' ? 'track' : rec.media_type,
                          rec.spotify_id
                        )}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='service-link'
                      >
                        Spotify
                      </a>
                    )}
                    {rec.youtube_id && (
                      <>
                        {rec.spotify_id && <span className='separator'>&middot;</span>}
                        <a
                          href={getYouTubeLink(rec.youtube_id, rec.media_type)}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='service-link'
                        >
                          YouTube
                        </a>
                      </>
                    )}
                    {rec.bandcamp_url && (
                      <>
                        {(rec.spotify_id || rec.youtube_id) && (
                          <span className='separator'>&middot;</span>
                        )}
                        <a
                          href={getBandcampLink(rec.bandcamp_url)}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='service-link'
                        >
                          Bandcamp
                        </a>
                      </>
                    )}
                    {rec.apple_id && (
                      <>
                        {(rec.spotify_id || rec.youtube_id || rec.bandcamp_url) && (
                          <span className='separator'>&middot;</span>
                        )}
                        <a
                          href={getAppleMusicLink(rec.media_type, rec.apple_id)}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='service-link'
                        >
                          Apple Music
                        </a>
                      </>
                    )}
                  </div>
                  <div className='info-line'>
                    <span className='metadata-text secondary'>
                      {recView === 'incoming' ? 'From' : 'To'}{' '}
                      <Link
                        to={`/profile/${recView === 'incoming' ? rec.sender : rec.receiver}`}
                        className='metadata-text primary interactive'
                      >
                        {recView === 'incoming' ? rec.sender : rec.receiver}
                      </Link>
                      {rec.message && ': '}
                      {rec.message}
                    </span>
                  </div>
                </div>
                <div className='media-actions'>
                  <MediaActionButton
                    onClick={() =>
                      itemQueueStatus[rec.media_id]
                        ? handleRemoveFromQueue(rec.media_type, rec.media_id)
                        : handleAddToQueue(rec.media_type, rec.media_id)
                    }
                    isActive={itemQueueStatus[rec.media_id]}
                    isLoading={itemQueueLoading[rec.media_id]}
                    actionType='Queue'
                  />
                  <MediaActionButton
                    onClick={() => {
                      const item = {
                        id: rec.media_id,
                        type: rec.media_type,
                        title: rec.media_title,
                        primary_artists: rec.primary_artists,
                        spotify_id: rec.spotify_id,
                        youtube_id: rec.youtube_id,
                        bandcamp_url: rec.bandcamp_url,
                        apple_id: rec.apple_music_id,
                      }
                      itemCollectionStatus[rec.media_id]
                        ? handleItemCollectionAction(item, true)
                        : handleItemCollectionAction(item, false)
                    }}
                    isActive={itemCollectionStatus[rec.media_id]}
                    isLoading={itemLoadingStatus[rec.media_id]}
                    actionType='Collection'
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )

  return (
    <div className='p-5'>
      {/* Tab Navigation */}
      <div className='mb-6 flex gap-4 border-b border-b-[#f0f0f0]'>
        {['friends', 'syncs'].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-4 pb-2 ${
              activeTab === tab ? 'border-b-2 border-[#f0f0f0] font-bold' : 'text-gray-500'
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {renderTabContent()}
    </div>
  )
}

export default Recs
