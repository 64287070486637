import MediaList from './MediaList'

function SearchResults({
  searchResults,
  loadingQueueActions,
  isInQueue,
  handleAddToQueue,
  handleRemoveFromQueue,
  handleRemoveFromCollection,
  handleAddToCollage,
  handleMoveToCollection,
}) {
  console.log('SearchResults component received:', searchResults)

  if (!searchResults) {
    return <div className='search-results'></div>
  }

  const renderSection = (title, items, type) => {
    if (!items || items.length === 0) return null

    return (
      <div className='search-section'>
        <h3>{title}</h3>
        <MediaList
          mediaItems={items}
          type={type === 'queue' ? items[0]?.media_type : type}
          loadingQueueActions={loadingQueueActions}
          isInQueue={isInQueue}
          onAddToQueue={handleAddToQueue}
          onRemoveFromQueue={handleRemoveFromQueue}
          onRemoveFromCollection={handleRemoveFromCollection}
          onAddToCollage={handleAddToCollage}
        />
      </div>
    )
  }

  return (
    <div className='search-results'>
      {renderSection('Queue', searchResults.queue, 'queue')}
      {renderSection('Songs', searchResults.songs, 'song')}
      {renderSection('Albums', searchResults.albums, 'album')}
      {renderSection('Playlists', searchResults.playlists, 'playlist')}
      {renderSection('Mixes', searchResults.mixes, 'mix')}
      {renderSection('Collages', searchResults.collages, 'collage')}
    </div>
  )
}

export default SearchResults
