import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'

function SongPage() {
  const [song, setSong] = useState(null)
  const { id } = useParams()

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
  } = useMediaActions('song', id)

  useEffect(() => {
    const fetchSong = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}songs/${id}/`)
        setSong(response.data)
      } catch (error) {
        console.error('Error fetching song:', error)
      }
    }

    fetchSong()
  }, [id])

  if (!song) return <div>Loading...</div>

  return (
    <div>
      <h1>{song.title}</h1>
      <p>
        Artists:{' '}
        {song.primary_artists
          .map((artist) => (
            <Link key={artist.id} to={`/artist/${artist.id}`}>
              {artist.name}
            </Link>
          ))
          .reduce((prev, curr) => [prev, ', ', curr])}
      </p>
      <p>Duration: {song.duration}</p>
      {song.album && (
        <p>
          Album: <Link to={`/album/${song.album.id}`}>{song.album.title}</Link>
        </p>
      )}
      <MediaActionButton
        onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
        isActive={inQueue}
        isLoading={queueLoading}
        actionType="Queue"
      />
      <br />
      <MediaActionButton
        onClick={() => handleCollectionAction(inCollection)}
        isActive={inCollection}
        isLoading={collectionLoading}
        actionType="Collection"
      />
      <p>
        Listen on:{' '}
        {song.spotify_id && (
          <a
            href={`https://open.spotify.com/track/${song.spotify_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify
          </a>
        )}
        {song.spotify_id && song.apple_id && ' | '}
        {song.apple_id && (
          <a
            href={`https://music.apple.com/us/song/${song.apple_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Music
          </a>
        )}
        {(song.spotify_id || song.apple_id) && song.youtube_id && ' | '}
        {song.youtube_id && (
          <a
            href={`https://www.youtube.com/watch?v=${song.youtube_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
        )}
        {(song.spotify_id || song.apple_id || song.youtube_id) &&
          song.bandcamp_url &&
          ' | '}
        {song.bandcamp_url && (
          <a href={song.bandcamp_url} target="_blank" rel="noopener noreferrer">
            Bandcamp
          </a>
        )}
      </p>
    </div>
  )
}

export default SongPage
