import { useState } from 'react'
import axios from 'axios'
import API_URL from '../../config'
import { setAuthToken } from '../../services/auth'

function AddFriendModal({ onClose, onSuccess }) {
  const [username, setUsername] = useState('')

  const sendFriendRequest = async (e) => {
    e.preventDefault()
    if (!username.trim()) return

    try {
      setAuthToken(localStorage.getItem('token'))
      await axios.post(`${API_URL}send-friend-request/`, {
        username: username.trim(),
      })
      alert('Friend request sent!')
      onSuccess()
      onClose()
    } catch (error) {
      console.error('Error sending friend request:', error)
      alert('Failed to send friend request')
    }
  }

  return (
    <div
      className='fixed inset-0 flex items-center justify-center'
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div style={{ backgroundColor: 'white' }} className='w-96 rounded-lg p-6'>
        <div className='mb-4 flex items-center justify-between'>
          <h3 className='text-lg font-bold'>Add Friend</h3>
          <button onClick={onClose} className='text-gray-500 hover:text-gray-700'>
            ×
          </button>
        </div>
        <form onSubmit={sendFriendRequest}>
          <input
            type='text'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder='Enter username'
            className='mb-4 w-full rounded border px-2 py-1 text-sm'
          />
          <button type='submit' className='context-button w-full py-1 text-sm'>
            Send Friend Request
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddFriendModal
