import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import { Link } from 'react-router-dom'
import {
  addToQueue,
  moveToCollection,
  removeFromQueue,
} from '../services/mediaService'
import API_URL from '../config'
import CollageSelectionModal from './CollageSelectionModal'
import MediaActionButton from './common/MediaActionButton'

function Library() {
  const [userMedia, setUserMedia] = useState({
    collection: {
      songs: [],
      albums: [],
      playlists: [],
      mixes: [],
      collages: [],
    },
    queue: [],
  })
  const [expandedSections, setExpandedSections] = useState({
    queue: false,
    collection: false,
    songs: false,
    albums: false,
    playlists: false,
    mixes: false,
    collages: false,
  })
  const [showCollageModal, setShowCollageModal] = useState(false)
  const [selectedMediaForCollage, setSelectedMediaForCollage] = useState(null)
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
  const abortControllerRef = useRef(null)
  const [loadingQueueItems, setLoadingQueueItems] = useState({})
  const [loadingQueueActions, setLoadingQueueActions] = useState({})

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }))
  }

  const fetchUserMedia = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    abortControllerRef.current = new AbortController()

    try {
      setAuthToken(localStorage.getItem('token'))
      const [collectionResponse, queueResponse] = await Promise.all([
        axios.get(`${API_URL}user-media/`, {
          signal: abortControllerRef.current.signal,
        }),
        axios.get(`${API_URL}user-queue/`, {
          signal: abortControllerRef.current.signal,
        }),
      ])

      const categorizedMedia = {
        collection: {
          songs: [],
          albums: [],
          playlists: [],
          mixes: [],
          collages: [],
        },
        queue: queueResponse.data,
      }

      const pluralMap = {
        song: 'songs',
        album: 'albums',
        playlist: 'playlists',
        mix: 'mixes',
        collage: 'collages',
      }

      collectionResponse.data.forEach((item) => {
        const collectionKey = pluralMap[item.media_type]
        if (categorizedMedia.collection[collectionKey]) {
          categorizedMedia.collection[collectionKey].push(item)
        }
      })

      setUserMedia(categorizedMedia)
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted')
      } else {
        console.error('Error fetching user media:', error)
      }
    } finally {
      abortControllerRef.current = null
    }
  }

  useEffect(() => {
    fetchUserMedia()

    // Cleanup function to abort any ongoing requests when the component unmounts
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  const handleAddToQueue = async (mediaType, mediaId) => {
    try {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: true,
      }))
      await addToQueue(mediaType, mediaId)
      await fetchUserMedia()
    } catch (error) {
      console.error('Error adding to queue:', error)
    } finally {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: false,
      }))
    }
  }

  const handleMoveToCollection = async (queueItemId) => {
    try {
      setLoadingQueueItems((prev) => ({ ...prev, [queueItemId]: true }))
      await moveToCollection(queueItemId)
      await fetchUserMedia() // Wait for this to complete
    } catch (error) {
      console.error('Error moving to collection:', error)
    } finally {
      // Clear loading state after UI update
      setLoadingQueueItems((prev) => ({ ...prev, [queueItemId]: false }))
    }
  }

  const handleRemoveFromQueue = async (mediaType, mediaId) => {
    try {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: true,
      }))
      const queueItem = userMedia.queue.find(
        (item) => item.media_type === mediaType && item.id === mediaId
      )
      if (queueItem) {
        await removeFromQueue(queueItem.queue_id)
        await fetchUserMedia()
      }
    } catch (error) {
      console.error('Error removing from queue:', error)
    } finally {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: false,
      }))
    }
  }

  const handleRemoveFromCollection = async (mediaType, mediaId) => {
    try {
      await axios.delete(`${API_URL}user-media/${mediaType}/${mediaId}/`)
      // Refresh the collection
      fetchUserMedia()
    } catch (error) {
      console.error('Error removing from collection:', error)
    }
  }

  const handleAddToCollage = (mediaType, mediaId, event) => {
    const rect = event.target.getBoundingClientRect()
    setModalPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    })
    setSelectedMediaForCollage({ mediaType, mediaId })
    setShowCollageModal(true)
  }

  const getSpotifyLink = (type, id) => {
    return `https://open.spotify.com/${type}/${id}`
  }

  const getYouTubeLink = (id, type) => {
    if (type === 'album') {
      return `https://www.youtube.com/playlist?list=${id}`
    }
    return `https://www.youtube.com/watch?v=${id}`
  }

  const getBandcampLink = (url) => {
    return url
  }

  const getAppleMusicLink = (type, id) => {
    if (type === 'song') {
      return `https://music.apple.com/us/song/${id}`
    } else if (type === 'album') {
      return `https://music.apple.com/us/album/${id}`
    }
    return '#' // Return a fallback link if type is neither song nor album
  }

  const renderMediaList = (mediaList, type) => (
    <div className="centered-list">
      <ul className="left-justify">
        {mediaList
          .sort((a, b) => new Date(b.added_at) - new Date(a.added_at))
          .filter((item) => type !== 'album' || item.direct_add)
          .map((item) => (
            <li key={item.id}>
              <Link to={`/${type}/${item.id}`}>
                <span>{item.title}</span>
              </Link>
              {type !== 'playlist' && type !== 'mix' && (
                <span>
                  {' '}
                  by{' '}
                  {item.primary_artists
                    .map((artist) => (
                      <Link key={artist.id} to={`/artist/${artist.id}`}>
                        {artist.name}
                      </Link>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])}
                </span>
              )}
              {type === 'playlist' && item.song_count && (
                <span> ({item.song_count} songs)</span>
              )}
              {item.spotify_id && (
                <span>
                  {' '}
                  (
                  <a
                    href={getSpotifyLink(
                      type === 'song' ? 'track' : type,
                      item.spotify_id
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Spotify
                  </a>
                  )
                </span>
              )}
              {item.youtube_id && (
                <span>
                  {' '}
                  (
                  <a
                    href={getYouTubeLink(item.youtube_id, type)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                  )
                </span>
              )}
              {item.bandcamp_url && (
                <span>
                  {' '}
                  (
                  <a
                    href={item.bandcamp_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bandcamp
                  </a>
                  )
                </span>
              )}
              {item.apple_id && (
                <span>
                  {' '}
                  (
                  <a
                    href={getAppleMusicLink(type, item.apple_id)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apple Music
                  </a>
                  )
                </span>
              )}
              {(type === 'playlist' || type === 'mix') && item.src_url && (
                <span>
                  {' '}
                  (
                  <a
                    href={item.src_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Source
                  </a>
                  )
                </span>
              )}
              <div>
                <MediaActionButton
                  onClick={() =>
                    isInQueue(type, item.id)
                      ? handleRemoveFromQueue(type, item.id)
                      : handleAddToQueue(type, item.id)
                  }
                  isActive={isInQueue(type, item.id)}
                  isLoading={loadingQueueActions[`${type}-${item.id}`]}
                  actionType="Queue"
                />
                <button
                  onClick={(event) => handleAddToCollage(type, item.id, event)}
                  className="add-button"
                >
                  + Collage
                </button>
                <button
                  onClick={() => handleRemoveFromCollection(type, item.id)}
                  className="remove-button"
                >
                  X
                </button>
              </div>
            </li>
          ))}
      </ul>
    </div>
  )

  const renderQueueItem = (item) => (
    <li key={item.queue_id} className="left-justify">
      <Link to={`/${item.media_type}/${item.id}`}>{item.title}</Link>
      {item.media_type !== 'playlist' &&
        item.media_type !== 'mix' &&
        item.primary_artists && (
          <span>
            {' '}
            by{' '}
            {item.primary_artists
              .map((artist) => (
                <Link key={artist.id} to={`/artist/${artist.id}`}>
                  {artist.name}
                </Link>
              ))
              .reduce((prev, curr) => [prev, ', ', curr])}
          </span>
        )}
      {item.media_type === 'playlist' && item.song_count && (
        <span> ({item.song_count} songs)</span>
      )}
      {item.media_type === 'mix' && item.src_url && (
        <span>
          {' '}
          (
          <a href={item.src_url} target="_blank" rel="noopener noreferrer">
            Source
          </a>
          )
        </span>
      )}
      <div>
        {!isInCollection(item) && (
          <MediaActionButton
            onClick={() => handleMoveToCollection(item.queue_id)}
            isActive={false}
            isLoading={loadingQueueItems[item.queue_id]}
            actionType="Collection"
            loadingText="Moving..."
          />
        )}
        <button
          onClick={() => handleRemoveFromQueue(item.media_type, item.id)}
          className="remove-button"
        >
          X
        </button>
      </div>
    </li>
  )

  const isInCollection = (item) => {
    const collectionKey = `${item.media_type}s`
    const collectionArray = userMedia.collection[collectionKey]
    return (
      Array.isArray(collectionArray) &&
      collectionArray.some((collectionItem) => collectionItem.id === item.id)
    )
  }

  const isInQueue = (mediaType, mediaId) => {
    return userMedia.queue.some(
      (item) => item.media_type === mediaType && item.id === mediaId
    )
  }

  return (
    <div className="library-container mt-5">
      <h2>
        queue
        <button
          onClick={() => toggleSection('queue')}
          className="toggle-button"
        >
          {expandedSections.queue ? '-' : '+'}
        </button>
      </h2>
      {expandedSections.queue &&
        (userMedia.queue.length > 0 ? (
          <div className="centered-list">
            <ul className="left-justify">
              {[...userMedia.queue]
                .sort((a, b) => new Date(a.added_at) - new Date(b.added_at))
                .map(renderQueueItem)}
            </ul>
          </div>
        ) : (
          <p>Your queue is empty.</p>
        ))}

      <h2>
        collection
        <button
          onClick={() => toggleSection('collection')}
          className="toggle-button"
        >
          {expandedSections.collection ? '-' : '+'}
        </button>
      </h2>

      {expandedSections.collection && (
        <>
          <h3>
            songs
            <button
              onClick={() => toggleSection('songs')}
              className="toggle-button"
            >
              {expandedSections.songs ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.songs &&
            (userMedia.collection.songs.length > 0 ? (
              renderMediaList(userMedia.collection.songs, 'song')
            ) : (
              <p>No songs found.</p>
            ))}

          <h3>
            albums
            <button
              onClick={() => toggleSection('albums')}
              className="toggle-button"
            >
              {expandedSections.albums ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.albums &&
            (userMedia.collection.albums.length > 0 ? (
              renderMediaList(userMedia.collection.albums, 'album')
            ) : (
              <p>No albums found.</p>
            ))}

          <h3>
            playlists
            <button
              onClick={() => toggleSection('playlists')}
              className="toggle-button"
            >
              {expandedSections.playlists ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.playlists &&
            (userMedia.collection.playlists.length > 0 ? (
              renderMediaList(userMedia.collection.playlists, 'playlist')
            ) : (
              <p>No playlists found.</p>
            ))}

          <h3>
            mixes
            <button
              onClick={() => toggleSection('mixes')}
              className="toggle-button"
            >
              {expandedSections.mixes ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.mixes &&
            (userMedia.collection.mixes.length > 0 ? (
              renderMediaList(userMedia.collection.mixes, 'mix')
            ) : (
              <p>No mixes found.</p>
            ))}
        </>
      )}

      <h2>
        collages
        <button
          onClick={() => toggleSection('collages')}
          className="toggle-button"
        >
          {expandedSections.collages ? '-' : '+'}
        </button>
      </h2>
      {expandedSections.collages && (
        <div className="centered-list">
          <ul className="left-justify">
            {userMedia.collection.collages.map((collage) => (
              <li key={collage.id}>
                <Link to={`/collage/${collage.id}`}>{collage.title}</Link>
                <button
                  onClick={() =>
                    handleRemoveFromCollection('collage', collage.id)
                  }
                  className="remove-button"
                >
                  X
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {showCollageModal && (
        <CollageSelectionModal
          collages={userMedia.collection.collages}
          onSelect={(collageId) =>
            handleAddToCollage(
              selectedMediaForCollage.mediaType,
              selectedMediaForCollage.mediaId,
              collageId
            )
          }
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={selectedMediaForCollage}
          fetchUserMedia={fetchUserMedia}
          position={modalPosition}
        />
      )}
    </div>
  )
}

export default Library
