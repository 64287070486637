import { useState, useEffect } from 'react'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'

function Profile() {
  const [username, setUsername] = useState('')
  const [spiralImage, setSpiralImage] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const response = await axios.get(`${API_URL}profile/`)
        console.log(
          'DEBUG - First 50 chars of received image:',
          response.data.spiral_image?.substring(0, 50)
        )
        setUsername(response.data.username)
        setSpiralImage(response.data.spiral_image)

        // Get cached image with a timestamp check
        const cacheKey = `spiral_${response.data.username}`
        const cachedData = localStorage.getItem(cacheKey)

        if (cachedData) {
          try {
            const { image, timestamp } = JSON.parse(cachedData)
            const oneDay = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

            // Use cached image if it's less than 24 hours old
            if (Date.now() - timestamp < oneDay) {
              setSpiralImage(image)
              setIsLoading(false)
              return
            }
          } catch (e) {
            console.error('Error parsing cached data:', e)
          }
        }

        // If no cache or cache is old, fetch new image
        setSpiralImage(response.data.spiral_image)

        // Cache the new image with timestamp
        localStorage.setItem(
          cacheKey,
          JSON.stringify({
            image: response.data.spiral_image,
            timestamp: Date.now(),
          })
        )
      } catch (error) {
        console.error('Error fetching profile:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProfile()
  }, [])

  return (
    <div className='container mt-5'>
      <div className='profile-container'>
        <div className='profile-image'>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <img
              src={spiralImage || ''}
              alt='Profile'
              onError={(e) => {
                console.error('Image failed to load:', e)
                localStorage.removeItem(`spiral_${username}`)
                setSpiralImage('')
              }}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          )}
        </div>
        <h3>{username}</h3>
      </div>
    </div>
  )
}

export default Profile
