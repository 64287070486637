import { useState, useEffect } from 'react'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import {
  addToQueue,
  removeFromQueue,
  checkMediaStatus,
} from '../services/mediaService'
import API_URL from '../config'

const useMediaActions = (mediaType, mediaId, items = [], isCollage = false) => {
  const [inQueue, setInQueue] = useState(false)
  const [inCollection, setInCollection] = useState(false)
  const [queueLoading, setQueueLoading] = useState(false)
  const [collectionLoading, setCollectionLoading] = useState(false)
  const [queueItemId, setQueueItemId] = useState(null)
  const [itemCollectionStatus, setItemCollectionStatus] = useState({})
  const [itemLoadingStatus, setItemLoadingStatus] = useState({})
  const [userCollection, setUserCollection] = useState([])
  const [itemQueueStatus, setItemQueueStatus] = useState({})
  const [itemQueueLoading, setItemQueueLoading] = useState({})

  const fetchUserMedia = async () => {
    try {
      setAuthToken(localStorage.getItem('token'))
      const response = await axios.get(`${API_URL}user-media/`)
      const userMediaData = response.data
      setUserCollection(userMediaData)

      if (isCollage && items.length > 0) {
        const collectionStatus = {}
        const loadingStatus = {}
        items.forEach((item) => {
          collectionStatus[item.id] = userMediaData.some(
            (media) => media[item.type] && media[item.type].id === item.id
          )
          loadingStatus[item.id] = false
        })
        setItemCollectionStatus(collectionStatus)
        setItemLoadingStatus(loadingStatus)
      } else {
        // Original track collection status logic
        const newTrackStatus = {}
        items.forEach((track) => {
          const trackId = track.id
          newTrackStatus[trackId] = userMediaData.some(
            (item) => item.media_type === 'song' && item.id === trackId
          )
        })
        setItemCollectionStatus(newTrackStatus)
      }
    } catch (error) {
      console.error('Error fetching user media:', error)
    }
  }

  useEffect(() => {
    if (items.length > 0 && userCollection.length > 0) {
      if (isCollage) {
        const collectionStatus = {}
        items.forEach((item) => {
          collectionStatus[item.id] = userCollection.some(
            (media) => media.media_type === item.type && media.id === item.id
          )
        })
        setItemCollectionStatus(collectionStatus)
      } else {
        const newTrackStatus = {}
        items.forEach((track) => {
          const trackId = track.id
          newTrackStatus[trackId] = userCollection.some(
            (item) => item.media_type === 'song' && item.id === trackId
          )
        })
        setItemCollectionStatus(newTrackStatus)
      }
    }
  }, [items, userCollection, isCollage])

  // Initial fetch of user media and status check
  useEffect(() => {
    if (mediaId) {
      fetchUserMedia()
      checkMediaStatus(mediaType, mediaId)
        .then((response) => {
          setInQueue(response.in_queue)
          setInCollection(response.in_collection)
          if (response.queue_id) {
            setQueueItemId(response.queue_id)
          }
        })
        .catch((error) => {
          console.error('Error checking media status:', error)
        })
    }
  }, [mediaId, mediaType])

  const handleCollectionAction = async (isRemoving = false) => {
    try {
      setCollectionLoading(true)
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/${mediaType}/${mediaId}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: mediaType,
          id: mediaId,
        })
      }
      await fetchUserMedia()
      const response = await checkMediaStatus(mediaType, mediaId)
      setInCollection(response.in_collection)
    } catch (error) {
      console.error(
        `Error ${isRemoving ? 'removing from' : 'adding to'} collection:`,
        error
      )
    } finally {
      setCollectionLoading(false)
    }
  }

  const fetchQueueStatus = async () => {
    try {
      const queueResponse = await axios.get(`${API_URL}user-queue/`)
      const queueData = queueResponse.data

      if (isCollage && items.length > 0) {
        const queueStatus = {}
        items.forEach((item) => {
          queueStatus[item.id] = queueData.some(
            (queueItem) =>
              queueItem.media_type === item.type && queueItem.id === item.id
          )
        })
        setItemQueueStatus(queueStatus)
      }
    } catch (error) {
      console.error('Error fetching queue status:', error)
    }
  }

  const handleAddToQueue = async (itemType, itemId) => {
    try {
      // If we're handling a specific item in a collage
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: true }))
        await addToQueue(itemType, itemId)
        await fetchQueueStatus()
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      }
      // If we're handling the main media object (album, playlist, etc.)
      else {
        setQueueLoading(true)
        await addToQueue(mediaType, mediaId)
        const response = await checkMediaStatus(mediaType, mediaId)
        setInQueue(response.in_queue)
        setInCollection(response.in_collection)
        if (response.queue_id) {
          setQueueItemId(response.queue_id)
        }
        setQueueLoading(false)
      }
    } catch (error) {
      console.error('Error adding to queue:', error)
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      } else {
        setQueueLoading(false)
      }
    }
  }

  const handleRemoveFromQueue = async (itemType, itemId) => {
    try {
      // If we're handling a specific item in a collage
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: true }))
        const queueResponse = await axios.get(`${API_URL}user-queue/`)
        const queueItem = queueResponse.data.find(
          (item) => item.media_type === itemType && item.id === itemId
        )
        if (queueItem) {
          await removeFromQueue(queueItem.queue_id)
        }
        await fetchQueueStatus()
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      }
      // If we're handling the main media object (album, playlist, etc.)
      else {
        setQueueLoading(true)
        if (queueItemId) {
          await removeFromQueue(queueItemId)
          const response = await checkMediaStatus(mediaType, mediaId)
          setInQueue(response.in_queue)
          setInCollection(response.in_collection)
          if (response.queue_id) {
            setQueueItemId(response.queue_id)
          }
        }
        setQueueLoading(false)
      }
    } catch (error) {
      console.error('Error removing from queue:', error)
      if (itemType && itemId) {
        setItemQueueLoading((prev) => ({ ...prev, [itemId]: false }))
      } else {
        setQueueLoading(false)
      }
    }
  }

  const handleItemCollectionAction = async (item, isRemoving = false) => {
    try {
      setItemLoadingStatus((prev) => ({ ...prev, [item.id]: true }))
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/${item.type}/${item.id}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: item.type,
          id: item.id,
        })
      }
      await fetchUserMedia()
    } catch (error) {
      console.error(
        `Error ${isRemoving ? 'removing from' : 'adding to'} collection:`,
        error
      )
    } finally {
      setItemLoadingStatus((prev) => ({ ...prev, [item.id]: false }))
    }
  }

  useEffect(() => {
    if (isCollage && items.length > 0) {
      fetchQueueStatus()
    }
  }, [items, isCollage])

  return {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    handleItemCollectionAction,
    fetchUserMedia,
    itemCollectionStatus,
    itemLoadingStatus,
    itemQueueStatus,
    itemQueueLoading,
  }
}

export default useMediaActions
