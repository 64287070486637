import { useNotification } from '../contexts/NotificationContext'
import { useLocation, Link } from 'react-router-dom'
import AccountDropdown from './AccountDropdown'

function NavigationBar({ auth, handleLogout }) {
  const { hasNewRecs } = useNotification()
  const location = useLocation()

  const isActive = (path) => location.pathname === path
  const getLinkClasses = (path) =>
    `text-contextBlack transition p-1 nav-link ${isActive(path) ? 'underline' : ''}`

  const NavItem = ({ to, children, extra }) => (
    <li className='whitespace-nowrap'>
      <Link to={to} className={getLinkClasses(to)}>
        {children}
        {extra}
      </Link>
    </li>
  )

  const mainLinks = [
    { to: '/library', label: 'library' },
    { to: '/add', label: 'add' },
    {
      to: '/recs',
      label: 'recs',
      extra: hasNewRecs && <span className='notification-dot' key={Date.now()}></span>,
    },
  ]

  return (
    <nav className='main-nav fixed left-0 right-0 top-0 z-30 bg-contextWhite px-5 py-5'>
      {auth ? (
        <div className='relative flex w-full items-center'>
          <ul className='absolute left-0'>
            <li className='whitespace-nowrap'>
              <Link to='/' className='logo-link flex rounded-[30px] p-2 transition'>
                <img src='/logomark.svg' alt='Context' className='h-6 w-6 transition' />
              </Link>
            </li>
          </ul>

          <ul className='mx-auto flex items-center gap-8'>
            {mainLinks.map(({ to, label, extra }) => (
              <NavItem key={to} to={to} extra={extra}>
                {label}
              </NavItem>
            ))}
          </ul>

          <ul className='absolute right-0 z-50'>
            <li>
              <AccountDropdown handleLogout={handleLogout} />
            </li>
          </ul>
        </div>
      ) : (
        <ul>
          <NavItem to='/login'>login</NavItem>
        </ul>
      )}
    </nav>
  )
}

export default NavigationBar
