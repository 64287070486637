import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { setAuthToken } from '../services/auth'
import API_URL from '../config'
import MediaActionButton from './common/MediaActionButton'
import useMediaActions from '../hooks/useMediaActions'

function PlaylistPage() {
  const [playlist, setPlaylist] = useState(null)
  const { id } = useParams()
  const [trackLoadingStatus, setTrackLoadingStatus] = useState({})

  const {
    inQueue,
    inCollection,
    queueLoading,
    collectionLoading,
    handleAddToQueue,
    handleRemoveFromQueue,
    handleCollectionAction,
    fetchUserMedia,
    itemCollectionStatus,
  } = useMediaActions('playlist', id, playlist?.songs || [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'))
        const playlistResponse = await axios.get(`${API_URL}playlists/${id}/`)
        setPlaylist(playlistResponse.data)

        const loadingStatus = {}
        playlistResponse.data.songs.forEach((song) => {
          loadingStatus[song.id] = false
        })
        setTrackLoadingStatus(loadingStatus)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [id])

  const handleitemCollectionAction = async (trackId, isRemoving) => {
    try {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: true }))
      if (isRemoving) {
        await axios.delete(`${API_URL}user-media/song/${trackId}/`)
      } else {
        await axios.post(`${API_URL}add-to-collection/`, {
          media_type: 'song',
          id: trackId,
        })
      }
      await fetchUserMedia()
    } catch (error) {
      console.error(
        `Error ${isRemoving ? 'removing' : 'adding'} track to collection:`,
        error
      )
    } finally {
      setTrackLoadingStatus((prev) => ({ ...prev, [trackId]: false }))
    }
  }

  const renderListenLinks = (song) => {
    const links = []
    if (song.spotify_id)
      links.push(
        <a
          key="spotify"
          href={`https://open.spotify.com/track/${song.spotify_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Spotify
        </a>
      )
    if (song.apple_id)
      links.push(
        <a
          key="apple"
          href={`https://music.apple.com/us/song/${song.apple_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Apple Music
        </a>
      )
    if (song.bandcamp_url)
      links.push(
        <a
          key="bandcamp"
          href={song.bandcamp_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Bandcamp
        </a>
      )
    if (song.youtube_id)
      links.push(
        <a
          key="youtube"
          href={`https://www.youtube.com/watch?v=${song.youtube_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube
        </a>
      )
    return links.length > 0
      ? links.reduce((prev, curr) => [prev, ' | ', curr])
      : 'No links available'
  }

  if (!playlist) return <div>Loading...</div>

  return (
    <div>
      <h1>{playlist.title}</h1>
      <p>Songs: {playlist.songs.length}</p>
      <MediaActionButton
        onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue}
        isActive={inQueue}
        isLoading={queueLoading}
        actionType="Queue"
      />
      <br />
      <MediaActionButton
        onClick={() => handleCollectionAction(inCollection)}
        isActive={inCollection}
        isLoading={collectionLoading}
        actionType="Collection"
      />
      <h2>Tracks:</h2>
      <ol>
        {playlist.songs.map((song) => (
          <li key={song.id}>
            <Link to={`/song/${song.id}`}>{song.title}</Link> -{' '}
            {renderListenLinks(song)}
            <MediaActionButton
              onClick={() =>
                handleitemCollectionAction(
                  song.id,
                  itemCollectionStatus[song.id]
                )
              }
              isActive={itemCollectionStatus[song.id]}
              isLoading={trackLoadingStatus[song.id]}
              actionType="Collection"
            />
          </li>
        ))}
      </ol>
    </div>
  )
}

export default PlaylistPage
