export const getSpotifyLink = (type, id) => {
  return `https://open.spotify.com/${type}/${id}`
}

export const getYouTubeLink = (id, type) => {
  if (type === 'album') {
    return `https://www.youtube.com/playlist?list=${id}`
  }
  return `https://www.youtube.com/watch?v=${id}`
}

export const getBandcampLink = (url) => {
  return url
}

export const getAppleMusicLink = (type, id) => {
  if (type === 'song') {
    return `https://music.apple.com/us/song/${id}`
  } else if (type === 'album') {
    return `https://music.apple.com/us/album/${id}`
  }
  return '#'
}
