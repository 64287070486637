import { useState } from 'react'
import QueueSection from './components/QueueSection'
import CollectionSection from './components/CollectionSection'
import LibraryNavigation from './components/LibraryNavigation'
import SearchResults from './components/LibrarySearchResults'
import { useUserMedia } from './hooks/useUserMedia'
import { addToQueue, moveToCollection, removeFromQueue } from '../../services/mediaService'
import axios from 'axios'
import API_URL from '../../config'
import { useLibrarySearch } from './hooks/useLibrarySearch'
import CollageSelectionModal from '../../components/CollageSelectionModal'

function Library() {
  const { userMedia, fetchUserMedia } = useUserMedia()
  const [loadingQueueItems, setLoadingQueueItems] = useState({})
  const [loadingQueueActions, setLoadingQueueActions] = useState({})
  const [currentSection, setCurrentSection] = useState('queue')
  const [currentCollectionSection, setCurrentCollectionSection] = useState('songs')
  const [showCollageModal, setShowCollageModal] = useState(false)
  const [selectedMediaForCollage, setSelectedMediaForCollage] = useState(null)
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
  const { showSearch, setShowSearch, setSearchQuery, handleSearch, getSearchResults } =
    useLibrarySearch(userMedia)

  const showSection = (sectionId) => {
    setCurrentSection(sectionId)
    if (sectionId !== 'search') {
      setShowSearch(false)
      setSearchQuery('')
    }
  }

  const isInQueue = (mediaType, mediaId) => {
    return userMedia.queue.some((item) => item.media_type === mediaType && item.id === mediaId)
  }

  const handleAddToQueue = async (mediaType, mediaId) => {
    try {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: true,
      }))
      await addToQueue(mediaType, mediaId)
      await fetchUserMedia()
    } catch (error) {
      console.error('Error adding to queue:', error)
    } finally {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: false,
      }))
    }
  }

  const handleRemoveFromQueue = async (mediaType, mediaId) => {
    try {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: true,
      }))
      const queueItem = userMedia.queue.find(
        (item) => item.media_type === mediaType && item.id === mediaId
      )
      if (queueItem) {
        await removeFromQueue(queueItem.queue_id)
        await fetchUserMedia()
      }
    } catch (error) {
      console.error('Error removing from queue:', error)
    } finally {
      setLoadingQueueActions((prev) => ({
        ...prev,
        [`${mediaType}-${mediaId}`]: false,
      }))
    }
  }

  const handleMoveToCollection = async (queueItemId) => {
    try {
      setLoadingQueueItems((prev) => ({ ...prev, [queueItemId]: true }))
      await moveToCollection(queueItemId)
      await fetchUserMedia()
    } catch (error) {
      console.error('Error moving to collection:', error)
    } finally {
      setLoadingQueueItems((prev) => ({ ...prev, [queueItemId]: false }))
    }
  }

  const handleRemoveFromCollection = async (mediaType, mediaId) => {
    try {
      await axios.delete(`${API_URL}user-media/${mediaType}/${mediaId}/`)
      await fetchUserMedia()
    } catch (error) {
      console.error('Error removing from collection:', error)
    }
  }

  const handleAddToCollage = (mediaType, mediaId, event) => {
    if (showCollageModal && selectedMediaForCollage?.id === mediaId) {
      setShowCollageModal(false)
    } else {
      const button = event.currentTarget
      const rect = button.getBoundingClientRect()
      setSelectedMediaForCollage({ type: mediaType, id: mediaId })
      setModalPosition({
        top: rect.bottom,
        left: rect.left,
      })
      setShowCollageModal(true)
    }
  }

  return (
    <div className='library-container'>
      <LibraryNavigation
        currentSection={currentSection}
        showSection={showSection}
        currentCollectionSection={currentCollectionSection}
        setCurrentCollectionSection={setCurrentCollectionSection}
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        onSearch={handleSearch}
      />

      {currentSection === 'queue' && (
        <QueueSection
          userMedia={userMedia}
          loadingQueueItems={loadingQueueItems}
          setLoadingQueueItems={setLoadingQueueItems}
          handleMoveToCollection={handleMoveToCollection}
          handleRemoveFromQueue={handleRemoveFromQueue}
        />
      )}

      {currentSection === 'collection' && (
        <CollectionSection
          userMedia={userMedia}
          currentCollectionSection={currentCollectionSection}
          handleRemoveFromCollection={handleRemoveFromCollection}
          handleAddToCollage={handleAddToCollage}
        />
      )}

      {showSearch && (
        <SearchResults
          searchResults={getSearchResults()}
          loadingQueueActions={loadingQueueActions || {}}
          isInQueue={isInQueue}
          handleAddToQueue={handleAddToQueue}
          handleRemoveFromQueue={handleRemoveFromQueue}
          handleRemoveFromCollection={handleRemoveFromCollection}
          handleAddToCollage={handleAddToCollage}
          handleMoveToCollection={handleMoveToCollection}
        />
      )}

      {showCollageModal && (
        <CollageSelectionModal
          position={modalPosition}
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={selectedMediaForCollage}
          fetchUserMedia={fetchUserMedia}
          collages={userMedia.collection.collages}
        />
      )}
    </div>
  )
}

export default Library
